import { Paper } from "@mui/material";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";
import { DeliveryAreaSection } from "./sections";

const StyledPaper = styled(Paper)`
  && {
    padding: 16px;
    border-radius: 10px;
  }
`;

export const DeliveryAreaForm = ({
  methods,
  disabled,
  renderFormActions = () => {},
}) => {
  return (
    <FormProvider {...methods}>
      <StyledPaper>
        <DeliveryAreaSection isDisabled={disabled} />
        {renderFormActions()}
      </StyledPaper>
    </FormProvider>
  );
};
