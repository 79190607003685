export const types = [
  {
    id: 1,
    value: "STRING",
    label: "String",
  },
  {
    id: 2,
    value: "URL",
    label: "Url",
  },
  {
    id: 3,
    value: "INT",
    label: "Integer",
  },
  {
    id: 4,
    value: "FLOAT",
    label: "Decimal",
  },
  {
    id: 5,
    value: "BOOLEAN",
    label: "Boolean",
  },
  {
    id: 6,
    value: "COMMA_SEPERATED_INT",
    label: "Comma-separated int",
  },
];

export const addTypeToProperties = (hub) => {
  const typedProperties = hub?.properties?.map((property) => {
    return property.type ? property : { ...property, type: "STRING" };
  });
  const typedDeliveryProperties = hub?.deliveryProperties?.map((property) => {


    return property.type ? property : { ...property, type: "STRING" };
  });
  // console.log({
  //   ...hub,
  //   properties: typedProperties || [],
  //   deliveryProperties: typedDeliveryProperties || [],
  // });
  return {
    ...hub,
    properties: typedProperties || [],
    deliveryProperties: typedDeliveryProperties || [],
  };
};
