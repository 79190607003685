import React, { useContext, useState, forwardRef } from "react";
import { Snackbar, Alert as MuiAlert } from "@mui/material";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const NotificationContext = React.createContext();

NotificationContext.displayName = "NotificationContext";

export const NotificationContextProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const notify = (notification) => {
    setOpen(true);
    setMessage(notification.message);
    setSeverity(notification.severity);
  };

  const values = { notify };

  return (
    <NotificationContext.Provider value={values}>
      {children}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => useContext(NotificationContext);
