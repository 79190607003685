import React, { useContext } from "react";
import { useAuthContext } from "./AuthContext";
import { getPermissions, mockUser } from "./permissionConfig";

export const PermissionContext = React.createContext();

PermissionContext.displayName = "PermissionContext";

export const PermissionContextProvider = ({ children }) => {
  const { user } = useAuthContext();
  const permissions = getPermissions(user);

  const values = {
    ...permissions,
  };

  return (
    <PermissionContext.Provider value={values}>
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermissionContext = () => useContext(PermissionContext);
