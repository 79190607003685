import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import Select from "../mui-components/Select";
import { useForm, FormProvider } from "react-hook-form";
import { closingReasons, closingReasonSchema } from "./closeReasonValidation";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const CloseReasonDialog = ({
  open,
  loading,
  onCancel = () => {},
  onAction = () => {},
  title,
  message,
  actionLabel = "DELETE",
  actionButtonProps = {},
}) => {
  const methods = useForm({
    defaultValues: {
      message: "",
    },
    resolver: closingReasonSchema,
  });

  const onClose = () => {
    onCancel();
    methods.reset({ message: "" });
  };

  const onValidAction = (formValue) => {
    onAction(formValue);
    methods.reset({ message: "" });
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <FormProvider {...methods}>
          <FormControl
            variant="standard"
            fullWidth
            style={{ marginTop: "16px" }}
          >
            <InputLabel id="state-select-label">Closing Reason</InputLabel>
            <Select
              name="message"
              labelId="state-select-label"
              label="Closing Reason"
              MenuProps={MenuProps}
            >
              {closingReasons?.map((reason) => (
                <MenuItem key={reason.id} value={reason.message}>
                  {reason.message}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={methods.handleSubmit(onValidAction)}
          disableRipple
          color="error"
          variant="contained"
          disabled={loading}
          {...actionButtonProps}
        >
          {actionLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
