import {useState} from "react";
import {Typography, Grid} from "@mui/material";
import {usePermissionContext} from "../../context/PermissionContext";
import {useGetDeliveryAreaQuery} from "../../sources/queries";

import {
    StyledHubsPageContainer,
    StyledHubsRestrictedPageContainer,
} from "../styles";

import {CloseReasonDialog} from "../../components/modals/CloseReasonDialog";
import {SearchHeader} from "../../components/SearchHeader";
import {DeliveryAreaInfo} from "../../components/DeliveryAreaInfo";
import {HubInfoSkeleton} from "../../components/placeholders/HubInfoSkeleton";
import {NoDeliveryArea} from "./NoDeliveryArea";
import {DeliveryAreaLocationCard} from "../../components/DeliveryAreaLocationCard";

export const DeliveryAreaPage = () => {
    const [open, setOpen] = useState(false);
    const [hubStatus, setHubStatus] = useState(null);

    const [search, setSearch] = useState("");
    const {data, isLoading} = useGetDeliveryAreaQuery({cacheTime: 0, search});
    const hubs = data?.data || [];
    const {
        availablePlaces, hasHubEditPermission, hasReadOnlyPermission,
        hasDeliveryAreaServingHubPermission, hasDeliveryAreaDeliveryFeePermission, hasAdminOrSupervisorPermission
    } =
        usePermissionContext();

    const {availableCities = [], availableCountries = []} =
    availablePlaces || {};

    const onCancel = () => {
        setOpen(false);
    };
    const onAction = ({message}) => {
        const {variables, mutation} = hubStatus;
        mutation({...variables, message});
        setOpen(false);
    };

    const onDeliveryAreaStateChange = (variables, mutation) => {
        if (variables?.state === "OPEN") {
            mutation(variables);
        } else {
            setOpen(true);
            setHubStatus({variables, mutation});
        }
    };

    if (hasHubEditPermission || hasReadOnlyPermission || hasDeliveryAreaServingHubPermission || hasDeliveryAreaDeliveryFeePermission || hasAdminOrSupervisorPermission) {
        return (
            <StyledHubsPageContainer maxWidth="lg">
                <SearchHeader header="Delivery area" setSearch={setSearch}/>
                <NoDeliveryArea
                    hubs={hubs}
                    isLoading={isLoading}
                    message={"No delivery area available"}
                />
                {isLoading && (
                    <>
                        <HubInfoSkeleton/>
                        <HubInfoSkeleton/>
                    </>
                )}

                {hubs?.map((hub) => {
                    return (
                        <DeliveryAreaInfo
                            key={hub.id}
                            onDeliveryAreaStateChange={onDeliveryAreaStateChange}
                            {...hub}
                        />
                    );
                })}
                <CloseReasonDialog
                    open={open}
                    onCancel={onCancel}
                    onAction={onAction}
                    title={`Close ${hubStatus?.variables?.polygonName} delivery area?`}
                    message="If you are sure you want to close this delivery area please choose the reason for closing."
                    actionLabel="Close delivery area"
                    actionButtonProps={{color: "primary"}}
                />
            </StyledHubsPageContainer>
        );
    }

    return (
        <StyledHubsRestrictedPageContainer maxWidth="lg">
            <Grid container spacing={6}>
                {!!availableCities.length && (
                    <Grid item xs={12}>
                        <Typography variant="h4">Managed Cities</Typography>
                    </Grid>
                )}
                {availableCities.map((el, index) => (
                    <Grid key={index} item>
                        <DeliveryAreaLocationCard {...el} />
                    </Grid>
                ))}

                {!!availableCountries.length && (
                    <Grid item xs={12}>
                        <Typography variant="h4">Managed Countries</Typography>
                    </Grid>
                )}
                {availableCountries.map((el, index) => (
                    <Grid key={index} item>
                        <DeliveryAreaLocationCard {...el} />
                    </Grid>
                ))}
            </Grid>
        </StyledHubsRestrictedPageContainer>
    );
};
