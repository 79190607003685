import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";

export const RedirectToLogin = ({ children }) => {
  const { token, isChecking } = useAuthContext();

  if (!token && !isChecking) {
    return <Navigate to="/login" replace />;
  }

  return children;
};
