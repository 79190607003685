import styled from "styled-components";
import { Link, useResolvedPath, useMatch } from "react-router-dom";

const StyledLink = styled(Link)`
  && {
    margin-right: 16px;
    font-family: "Inter-Regular";
    font-size: 16px;
    color: ${({ active }) => (active ? "#56e39f" : "white")};
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      color: #56e39f;
    }
  }
`;

const CustomLink = ({ children, to, ...props }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <div>
      <StyledLink active={match} to={to} {...props}>
        {children}
      </StyledLink>
    </div>
  );
};

export default CustomLink;
