import { Button } from "@mui/material";

export const columns = [
  { id: "username", label: "Name", minWidth: 150 },
  {
    id: "roleNames",
    label: "Roles",
    minWidth: 250,
  },
  {
    id: "edit",
    width: 70,

    format: ({ row, onEdit }) => {
      // console.log(row);
      return (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => onEdit(row.id)}
        >
          Edit
        </Button>
      );
    },
  },
  {
    id: "delete",
    width: 70,

    format: ({ row, onDelete }) => {
      return (
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={() => onDelete(row.id)}
        >
          Delete
        </Button>
      );
    },
  },
];
