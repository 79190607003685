import styled from "styled-components";
import { Dialog, DialogContent, Grid, MenuItem } from "@mui/material";

// DIFF MODAL

export const StyledDiffModalDialog = styled(Dialog)`
  && {
    height: 100%;
  }
`;

// FORM MODAL

export const StyledFormModalDialogContent = styled(DialogContent)`
  && {
    padding: 32px;
  }
`;

export const StyledFormModalGridContainer = styled(Grid)`
  && {
    padding: 16px;
  }
`;

export const StyledFormModalMenuItem = styled(MenuItem)`
  && {
    white-space: unset;
    word-break: break-all;
  }
`;
