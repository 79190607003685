import styled from "styled-components";
import {
  Paper,
  TextField,
  Typography,
  Container,
  Button,
  Link,
  Grid,
} from "@mui/material";

export const StyledTableContainer = styled(Paper)`
  && {
    width: 100%;
    overflow: hidden;
    margin-top: 16px;
  }
`;

export const StyledTableHeaderContainer = styled(Container)`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(5, 63, 110, 0.03);
    padding: 16px;
  }
`;

// USERS PAGE

export const StyledUsersPageContainer = styled(Container)`
  && {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 16px;
  }
`;

export const StyledUserPageHeader = styled(Typography)`
  && {
    margin-top: 32px;
  }
`;

export const StyledUserTableSearch = styled(TextField)`
  width: 250px;
`;

// LOGS PAGE

export const StyledLogsPageContainer = styled(Container)`
  && {
    padding: 32px 16px;
    height: 100%;
  }
`;

export const StyledLogsTableDatesContainer = styled.div`
  && {
    display: flex;
    align-items: center;
  }
`;

export const StyledDatePickerSpacer = styled(Typography)`
  && {
    margin: 0 16px;
  }
`;

export const StyledLogsTableClearButton = styled(Button)`
  && {
    margin-left: 16px;
  }
`;

// HUBS PAGE

export const StyledHubsPageContainer = styled(Container)`
  && {
    padding: 32px 16px;
    height: 100%;
  }
`;

export const StyledHubsRestrictedPageContainer = styled(Container)`
  && {
    padding: 32px 0;
  }
`;

export const StyledNoHubsContainer = styled(Container)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    flex-direction: column;
  }
`;

export const StyledNoHubsLink = styled(Link)`
  && {
    cursor: pointer;
    margin-top: 8px;
  }
`;

// CREATE AND EDIT HUB PAGE

export const StyledHubFormPageContainer = styled(Container)`
  && {
    padding-bottom: 32px;
  }
`;

export const StyledHubFormGridContainer = styled(Grid)`
  && {
    padding: 16px;
  }
`;

export const StyledHubFormTitleGridContainer = styled(Grid)`
  && {
    padding: 32px 0 16px 0;
  }
`;

export const StyledHubFormGridButton = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
  }
`;
