import styled from "styled-components";
import { Grid, Skeleton, Paper } from "@mui/material";

export const StyledHubCardSkeletonPaper = styled(Paper)`
  && {
    padding: 16px;
    padding-top: 0;
    border-radius: 10px;
  }
`;

export const StyledFormSkeletonTitleGrid = styled(Grid)`
  && {
    padding: 32px 0 16px 0;
  }
`;

export const StyledFormSkeletonPaper = styled(Paper)`
  && {
    padding: 32px;
    padding-top: 32px;
  }
`;

export const StyledFormSkeleton = styled(Skeleton)`
  && {
    margin-top: 16px;
  }
`;
