import { Skeleton, TableRow, TableCell } from "@mui/material";

export const TableSkeleton = ({ columns }) => {
  return Array.from(Array(7)).map((_, rowIndex) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
        {Array.from(Array(columns || 4)).map((_, columnIndex) => {
          return (
            <TableCell key={columnIndex}>
              <Skeleton />
            </TableCell>
          );
        })}
      </TableRow>
    );
  });
};
