export const initialValues = {
    id: "",
    name: "",
    country: "",
    countryISO: "",
    state: "",
    polygonFileName: "",
    credentials: {
        domain: "",
        apiKey: "",
        password: "",
        storefrontAccessToken: "",
    },
    intercom: {
        androidApiKey: "",
        androidId: "",
        iosApiKey: "",
        iosId: "",
    },
    polygonCoordinates: null,
    address: "",
    coordinates: {
        lat: "",
        lng: "",
    },
    timeZone: "",
    useWebViewCheckout: false,
    configuration: {
        tipsEnabled: false,
        orderMinAmount: "",
        iconPath: "",
        isIndependent: true,
        country: null,
        province: null,
        city: null,
        zip: null,
    },
    etaValue: {
        id: "",
    },
    etaReason: {
        id: "",
    },
    triggers: [],
    properties: [],
    deliveryProperties: [],
};


export const deliveryAreaInitialValue = {
    id: null,
    polygonGeometry: null,
    defaultEtaValue: null,
    etaValue: null,
    etaReason: null,
    polygonName: null,
    state: null,
    hubId: null,
    dakiPlusHubId: null,
    fulfillmentHubIdForPlus: null,
    deliveryAreaConfiguration: null,
    deliveryFeeAreaConfiguration: null
}