import {useEffect} from "react";
import {useAuthContext} from "../../context/AuthContext";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useNotificationContext} from "../../context/NotificationContext";
import {StyledLoginPageContainer} from "../Login/styles";


export const LoginSuccess = () => {
    const navigate = useNavigate();
    const { signIn } = useAuthContext();
    const { notify } = useNotificationContext();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("t");

    useEffect((()=>{
        setTimeout(() => {
            if(token){
                signIn({ token });
                navigate("/")
            }
            else {
                notify({
                    severity: "error",
                    message: "The username or password is incorrect.",
                });
                navigate("/login");
            }
        }, 300);
    }),[token])

    return (
        <StyledLoginPageContainer>
        </StyledLoginPageContainer>
    );
};

export  default LoginSuccess;
