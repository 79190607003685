import React, {useState} from 'react';
import {useCloseHubsByCityMutation} from "../../sources/mutations";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export const CloseHubsDialog = ({
  open,
  onCancel = () => {},
  title,
  message,
}) => {
  const [values, setValues] = useState({ cityCode: '' })
  const onValidateInput = (e) => {
    const value = e.target.value;
    if(!value) return;
    setValues({ ...values, cityCode:  value })
  }

  const { mutate: closeHubsByCityCode} = useCloseHubsByCityMutation({ cacheTime: 0, cityCode: values?.cityCode.toUpperCase()});

  const onAction = () => {
    if (! (values && values.cityCode)) return;
    closeHubsByCityCode();
    onCancel();
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
        <DialogContentText>
              <div className="mt-5">
                <input onChange={onValidateInput} className="delete-input-confirm" />
              </div>
        </DialogContentText>


      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={onAction}
          disableRipple
          color="success"
          variant="contained"
          disabled={!(values && values.cityCode.length > 1)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
