import { FormControl, InputLabel, MenuItem } from "@mui/material";
import {
  StyledSectionTitle,
  StyledSectionContainerGrid,
  StyledSectionInputGroupGrid,
} from "./styles";

import TextField from "../../mui-components/TextField";
import Select from "../../mui-components/Select";

export const HubSection = ({ isDisabled }) => {
  return (
    <>
      <StyledSectionTitle variant="h6">Hub</StyledSectionTitle>
      <StyledSectionContainerGrid container spacing={4}>
        <StyledSectionInputGroupGrid item md={6} sm={12}>
          <TextField
            disabled={isDisabled}
            name="id"
            label="ID"
            variant="standard"
          />
          <TextField
            disabled={isDisabled}
            name="country"
            label="Country"
            variant="standard"
          />
          <TextField
            disabled={isDisabled}
            name="timeZone"
            label="Time Zone"
            variant="standard"
          />
        </StyledSectionInputGroupGrid>
        <StyledSectionInputGroupGrid item md={6} sm={12}>
          <TextField
            disabled={isDisabled}
            name="name"
            label="Name"
            variant="standard"
          />
          <TextField
            disabled={isDisabled}
            name="countryISO"
            label="Country ISO"
            variant="standard"
          />
          <FormControl variant="standard">
            <InputLabel id="state-select-label">State</InputLabel>
            <Select
              disabled={isDisabled}
              name="state"
              labelId="state-select-label"
              label="State"
            >
              <MenuItem value={"OPEN"}>OPEN</MenuItem>
              <MenuItem value={"CLOSED"}>CLOSED</MenuItem>
            </Select>
          </FormControl>
        </StyledSectionInputGroupGrid>
        <StyledSectionInputGroupGrid item xs={12}>
          <TextField
            disabled={isDisabled}
            name="polygonFileName"
            label="Polygon"
            variant="standard"
          />
        </StyledSectionInputGroupGrid>
      </StyledSectionContainerGrid>
    </>
  );
};
