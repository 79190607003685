import { Button, Typography, Grid } from "@mui/material";
import { useForm } from "react-hook-form";

import {
  StyledHubFormPageContainer,
  StyledHubFormGridContainer,
  StyledHubFormGridButton,
  StyledHubFormTitleGridContainer,
} from "../styles";

import { useCreateDeliveryAreaMutation } from "../../sources/mutations";

import { DeliveryAreaForm } from "../../components/form/DeliveryAreaForm";
import { deliveryAreaInitialValue } from "../../components/form/initialValues";
import { delivery_area_schema } from "../../components/form/validation";

export const CreateDeliveryAreaPage = () => {
  const methods = useForm({
    defaultValues: deliveryAreaInitialValue,
    resolver: delivery_area_schema,
  });
  const { mutate: createDeliveryArea, isLoading } = useCreateDeliveryAreaMutation();

  return (
    <StyledHubFormPageContainer maxWidth="md">
      <StyledHubFormTitleGridContainer container>
        <Grid item sx={12}>
          <Typography variant="h4">Create Delivery Area</Typography>
        </Grid>
      </StyledHubFormTitleGridContainer>
      <DeliveryAreaForm
        methods={methods}
        renderFormActions={() => {
          return (
            <StyledHubFormGridContainer container spacing={4}>
              <StyledHubFormGridButton item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  disableRipple
                  color="success"
                  onClick={methods.handleSubmit(createDeliveryArea)}
                  disabled={isLoading}
                >
                  Create Delivery Area
                </Button>
              </StyledHubFormGridButton>
            </StyledHubFormGridContainer>
          );
        }}
      />
    </StyledHubFormPageContainer>
  );
};
