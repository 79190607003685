import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { useAuthContext } from "../context/AuthContext";
import { useNotificationContext } from "../context/NotificationContext";
import { logInToUrl, postToUrl, putToUrl, deleteToUrl, patchToUrl } from "./methods";
import { getEnv } from "../utilities/getEnv";

const baseURL = getEnv("REACT_APP_API_ENDPOINT");

export const useLoginMutation = (options) => {
  const { notify } = useNotificationContext();
  const navigate = useNavigate();
  const { signIn } = useAuthContext();
  const url = `${baseURL}/authenticate`;

  const mutationOptions = {
    onSuccess: (result) => {
      if (result.success) {
        signIn({ token: result.data.token });
        navigate("/");
      } else {
        notify({
          severity: "error",
          message: "The username or password is incorrect.",
        });
      }
    },
    onError: () => {
      notify({ severity: "error", message: "Something went wrong." });
    },
    ...options,
  };

  return useMutation(
    (credentials) => logInToUrl(url, credentials),
    mutationOptions
  );
};

export const useCreateHubMutation = (options) => {
  const { notify } = useNotificationContext();
  const navigate = useNavigate();
  const { token } = useAuthContext();
  const url = `${baseURL}/hubs`;

  const mutationOptions = {
    onSuccess: ({ success, message }) => {
      if (success) {
        navigate("/");
        notify({ severity: "success", message: "Hub created successfully !" });
      } else {
        notify({ severity: "error", message });
      }
    },
    onError: () => {
      notify({ severity: "error", message: "Something went wrong." });
    },
    ...options,
  };

  return useMutation((body) => postToUrl(url, token, body), mutationOptions);
};

export const useCreateDeliveryAreaMutation = (options) => {
  const { notify } = useNotificationContext();
  const navigate = useNavigate();
  const { token } = useAuthContext();
  const url = `${baseURL}/delivery-area`;

  const mutationOptions = {
    onSuccess: ({ success, message }) => {
      if (success) {
        navigate("/delivery-area");
        notify({ severity: "success", message: "Delivery area created successfully !" });
      } else {
        notify({ severity: "error", message });
      }
    },
    onError: () => {
      notify({ severity: "error", message: "Something went wrong." });
    },
    ...options,
  };

  return useMutation((body) => postToUrl(url, token, body), mutationOptions);
};

export const useEditHubMutation = (options) => {
  console.log("useEditHubMutation");
  const { token } = useAuthContext();
  const url = `${baseURL}/hubs`;

  return useMutation((body) => putToUrl(url, token, body), options);
};

export const usePatchDeliveryAreaMutation = (options) => {
  const { token } = useAuthContext();
  const url = `${baseURL}/delivery-area`;

  return useMutation((body) => patchToUrl(url, token, body), options);
};

export const useEditDeliveryAreaMutation = (options) => {
  const { token } = useAuthContext();
  const url = `${baseURL}/delivery-area`;

  return useMutation((body) => putToUrl(url, token, body), options);
};

export const useSwitchHubStateMutation = (options) => {
  const { token } = useAuthContext();
  const url = `${baseURL}/hubs/${options.id}/switch-status`;

  return useMutation((body) => putToUrl(url, token, body), options);
};

export const useSwitchDeliveryAreaStateMutation = (options) => {
  const { token } = useAuthContext();
  const url = `${baseURL}/delivery-area/${options.id}/switch-status`;

  return useMutation((body) => putToUrl(url, token, body), options);
};

export const useDeleteHubMutation = (options) => {
  const { notify } = useNotificationContext();
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuthContext();
  const url = `${baseURL}/hubs`;

  const mutationOptions = {
    onSuccess: ({ success, message }) => {
      if (success) {
        navigate("/");
        notify({ severity: "success", message: "Hub deleted successfully !" });
      } else {
        notify({ severity: "error", message });
      }
    },
    onError: () => {
      notify({ severity: "error", message: "Something went wrong." });
    },
    ...options,
  };

  return useMutation(() => deleteToUrl(url, token, { id }), mutationOptions);
};

export const useDeleteDeliveryAreaMutation = (options) => {
  const { notify } = useNotificationContext();
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuthContext();
  const url = `${baseURL}/delivery-area`;

  const mutationOptions = {
    onSuccess: ({ success, message }) => {
      if (success) {
        navigate("/delivery-area");
        notify({
          severity: "success",
          message: "Delivery area deleted successfully !",
        });
      } else {
        notify({ severity: "error", message });
      }
    },
    onError: () => {
      notify({ severity: "error", message: "Something went wrong." });
    },
    ...options,
  };

  return useMutation(() => deleteToUrl(url, token, { id }), mutationOptions);
};

export const useCreateUserMutation = (options) => {
  const queryClient = useQueryClient();
  const { notify } = useNotificationContext();
  const { token } = useAuthContext();
  const url = `${baseURL}/users`;

  const mutationOptions = {
    ...options,
    onSuccess: ({ success, message }) => {
      if (success) {
        queryClient.invalidateQueries("getUsers");
        notify({
          severity: "success",
          message: "New user was created successfully!",
        });
        options?.onSuccess();
      } else {
        notify({ severity: "error", message });
      }
    },
    onError: () => {
      notify({ severity: "error", message: "Something went wrong." });
    },
  };

  return useMutation((body) => postToUrl(url, token, body), mutationOptions);
};

export const useEditUserMutation = (options) => {
  const queryClient = useQueryClient();
  const { notify } = useNotificationContext();
  const { token } = useAuthContext();
  const url = `${baseURL}/users`;

  const mutationOptions = {
    ...options,
    onSuccess: ({ success, message }) => {
      queryClient.invalidateQueries("getUsers");

      if (success) {
        notify({
          severity: "success",
          message: "User was updated successfully!",
        });
        options?.onSuccess();
      } else {
        notify({ severity: "error", message });
      }
    },
    onError: () => {
      notify({ severity: "error", message: "Something went wrong." });
    },
  };

  return useMutation((body) => putToUrl(url, token, body), mutationOptions);
};

export const useDeleteUserMutation = (options) => {
  const queryClient = useQueryClient();
  const { notify } = useNotificationContext();
  const { token } = useAuthContext();
  const url = `${baseURL}/users?id=${options.userId}`;

  const mutationOptions = {
    ...options,
    onSuccess: ({ success, message, error }) => {
      if (success) {
        queryClient.invalidateQueries("getUsers");
        notify({
          severity: "success",
          message: "User was deleted successfully !",
        });
        options?.onSuccess();
      } else {
        notify({ severity: "error", message: message || error });
      }
    },
    onError: () => {
      notify({ severity: "error", message: "Something went wrong." });
    },
  };

  return useMutation(() => deleteToUrl(url, token), mutationOptions);
};


export const useCloseHubsByCityMutation = (options) => {
  const { token } = useAuthContext();
  const url = `${baseURL}/hubs/close-hubs/${options.cityCode}`;

  return useMutation((body) => putToUrl(url, token, body), options);
};


export const useEditDeliveryAreaHubIdMutation = (options) => {
  const { token } = useAuthContext();
  const url = `${baseURL}/delivery-area/edit-serving-hub`;

  return useMutation((body) => putToUrl(url, token, body), options);
};

export const useEditDeliveryAreaDeliveryFeeMutation = (options) => {
  const { token } = useAuthContext();
  const url = `${baseURL}/delivery-area/edit-delivery-fees`;

  return useMutation((body) => putToUrl(url, token, body), options);
};

export const uploadDeliveryAreasWithCsv = ({token, file}) => {
  if (!file) {
    return;
  }

  const uploadDeliveryAreaUrl = `${baseURL}/delivery-area/csv-update-properties`;

  const formData = new FormData();
  formData.append('file', file);


  axios.post(uploadDeliveryAreaUrl, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'authorization': token,
      'Accept': '*/*'
    }
  }).then(response => {
    console.log(response);
  }).catch(error => {
    console.log(error);
  });

}


