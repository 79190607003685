import { Grid, Skeleton, Stack } from "@mui/material";
import { StyledHubCardSkeletonPaper } from "./styles";

export const HubInfoSkeleton = () => {
  return (
    <StyledHubCardSkeletonPaper>
      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={2}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={140}
            height={30}
          />
        </Grid>
        <Grid item xs={10} justifyContent="flex-end" display="flex">
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={260}
            height={50}
          />
        </Grid>
        {Array.from(Array(4)).map((_, index) => (
          <Grid key={index} item xs={3}>
            <Stack>
              <Skeleton animation="wave" variant="rectangular" height={200} />
            </Stack>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="text" />
        </Grid>
      </Grid>
    </StyledHubCardSkeletonPaper>
  );
};
