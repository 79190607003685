import { Button, Typography, Grid } from "@mui/material";
import { useForm } from "react-hook-form";

import {
  StyledHubFormPageContainer,
  StyledHubFormGridContainer,
  StyledHubFormGridButton,
  StyledHubFormTitleGridContainer,
} from "../styles";

import { useCreateHubMutation } from "../../sources/mutations";

import { HubForm } from "../../components/form/HubForm";
import { initialValues } from "../../components/form/initialValues";
import { schema } from "../../components/form/validation";

export const CreateHubPage = () => {
  const methods = useForm({
    defaultValues: initialValues,
    resolver: schema,
  });
  const { mutate: createHub, isLoading } = useCreateHubMutation();

  return (
    <StyledHubFormPageContainer maxWidth="md">
      <StyledHubFormTitleGridContainer container>
        <Grid item sx={12}>
          <Typography variant="h4">Create Hub</Typography>
        </Grid>
      </StyledHubFormTitleGridContainer>
      <HubForm
        methods={methods}
        renderFormActions={() => {
          return (
            <StyledHubFormGridContainer container spacing={4}>
              <StyledHubFormGridButton item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  disableRipple
                  color="success"
                  onClick={methods.handleSubmit(createHub)}
                  disabled={isLoading}
                >
                  Create Hub
                </Button>
              </StyledHubFormGridButton>
            </StyledHubFormGridContainer>
          );
        }}
      />
    </StyledHubFormPageContainer>
  );
};
