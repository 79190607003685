import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

import { StyledNoHubsContainer, StyledNoHubsLink } from "../styles";

export const NoHubs = ({ hubs, isLoading, message, showLink }) => {
  const navigate = useNavigate();

  if (hubs.length || isLoading) return null;

  return (
    <StyledNoHubsContainer>
      <Typography variant="h3" color="grey.500">
        {message}
      </Typography>
      {showLink && (
        <StyledNoHubsLink
          variant="h5"
          underline="always"
          onClick={() => navigate("/")}
        >
          Go back to the main page
        </StyledNoHubsLink>
      )}
    </StyledNoHubsContainer>
  );
};
