import {
  Button,
  Typography,
  Grid,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useFormContext, useFieldArray } from "react-hook-form";

import {
  StyledSectionContainerGrid,
  StyledSectionInputGroupGrid,
  StyledSectionAppendableContainerGrid,
} from "./styles";

import TextField from "../../mui-components/TextField";
import Select from "../../mui-components/Select";
import { types } from "../propertyTypes";

export const DeliveryPropertiesSection = ({ isDisabled }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "deliveryProperties",
  });

  return (
    <>
      <StyledSectionContainerGrid container spacing={4}>
        <StyledSectionInputGroupGrid item xs={6}>
          <Typography variant="h6">Delivery Properties</Typography>
        </StyledSectionInputGroupGrid>
        <StyledSectionInputGroupGrid item xs={6}>
          <Button
            disabled={isDisabled}
            variant="outlined"
            color="success"
            disableRipple
            onClick={() => append({ name: "", type: "STRING", value: "" })}
          >
            Add
          </Button>
        </StyledSectionInputGroupGrid>
      </StyledSectionContainerGrid>
      {fields.map((field, index) => (
        <StyledSectionAppendableContainerGrid
          key={field.id}
          container
          spacing={4}
        >
          <StyledSectionInputGroupGrid item xs={3}>
            <TextField
              disabled={isDisabled}
              name={`deliveryProperties.${index}.name`}
              label="Name"
              variant="standard"
            />
          </StyledSectionInputGroupGrid>
          <StyledSectionInputGroupGrid item xs={3}>
            <FormControl variant="standard" fullWidth>
              <InputLabel disabled={isDisabled} id="delivery-properties-type">
                Type
              </InputLabel>
              <Select
                disabled={isDisabled}
                name={`deliveryProperties.${index}.type`}
                labelId="delivery-properties-type"
                label="Type"
              >
                {types?.map((type) => (
                  <MenuItem key={type.id} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </StyledSectionInputGroupGrid>
          <StyledSectionInputGroupGrid item xs={4}>
            <TextField
              disabled={isDisabled}
              name={`deliveryProperties.${index}.value`}
              label="Value"
              variant="standard"
            />
          </StyledSectionInputGroupGrid>
          <StyledSectionInputGroupGrid item xs={2}>
            <Button
              disabled={isDisabled}
              variant="outlined"
              color="error"
              disableRipple
              onClick={() => remove(index)}
            >
              Remove
            </Button>
          </StyledSectionInputGroupGrid>
        </StyledSectionAppendableContainerGrid>
      ))}

      {!fields.length && (
        <StyledSectionContainerGrid container spacing={4}>
          <Grid item>
            <Typography color="grey.500">
              No delivery properties available, press the ADD button to add a
              new delivery property
            </Typography>
          </Grid>
        </StyledSectionContainerGrid>
      )}
    </>
  );
};
