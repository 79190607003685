import { Grid } from "@mui/material";
import {
  StyledSectionTitle,
  StyledSectionInputGroupGrid,
  StyledSectionContainerGrid,
} from "./styles";

import TextField from "../../mui-components/TextField";
import Checkbox from "../../mui-components/Checkbox";

export const ConfigurationSection = ({ isDisabled }) => {
  return (
    <>
      <StyledSectionTitle variant="h6">Configuration</StyledSectionTitle>
      <StyledSectionContainerGrid container spacing={4}>
        <StyledSectionInputGroupGrid item md={6} sm={12}>
          <TextField
            disabled={isDisabled}
            name="configuration.orderMinAmount"
            label="Order Minimum Amount"
            variant="standard"
          />
        </StyledSectionInputGroupGrid>
        <StyledSectionInputGroupGrid item md={6} sm={12}>
          <TextField
            disabled={isDisabled}
            name="configuration.iconPath"
            label="Icon Path"
            variant="standard"
          />
        </StyledSectionInputGroupGrid>
        <Grid item xs={12}>
          <Checkbox
            disabled={isDisabled}
            name="configuration.tipsEnabled"
            label="Tips Enabled"
          />
        </Grid>
      </StyledSectionContainerGrid>
    </>
  );
};
