import {
  StyledSectionTitle,
  StyledSectionContainerGrid,
  StyledSectionInputGroupGrid,
} from "./styles";
import TextField from "../../mui-components/TextField";

export const CredentialsSection = ({ isDisabled }) => {
  return (
    <>
      <StyledSectionTitle variant="h6">Credentials</StyledSectionTitle>
      <StyledSectionContainerGrid container spacing={4}>
        <StyledSectionInputGroupGrid item md={6} sm={12}>
          <TextField
            disabled={isDisabled}
            name="credentials.domain"
            label="Domain"
            variant="standard"
          />
          <TextField
            disabled={isDisabled}
            name="credentials.password"
            label="Password"
            variant="standard"
          />
        </StyledSectionInputGroupGrid>
        <StyledSectionInputGroupGrid item md={6} sm={12}>
          <TextField
            disabled={isDisabled}
            name="credentials.apiKey"
            label="API Key"
            variant="standard"
          />
          <TextField
            disabled={isDisabled}
            name="credentials.storefrontAccessToken"
            label="Store front access token"
            variant="standard"
          />
        </StyledSectionInputGroupGrid>
      </StyledSectionContainerGrid>
    </>
  );
};
