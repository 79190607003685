import SearchIcon from "@mui/icons-material/Search";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { DiffModal } from "../../components/modals/DiffModal";
import { DataTable } from "../../components/Table";
import { useAuthContext } from "../../context/AuthContext";
import { exportLogs, useGetLogs } from "../../sources/queries";
import { useDebouncedEffect } from "../../utilities/useDebouncedEffect";
import {
  StyledDatePickerSpacer, StyledLogsPageContainer,

  StyledLogsTableClearButton, StyledLogsTableDatesContainer, StyledTableContainer,
  StyledTableHeaderContainer,
  StyledUserTableSearch
} from "../styles";
import { columns } from "./tableConfig";



export const LogsPage = () => {
  const { token } = useAuthContext();

  const [dates, setDates] = useState({ startDate: null, endDate: null });
  const [diff, setDiff] = useState({
    open: false,
    date: null,
    previousObject: null,
    updatedObjec: null,
    user: null,
    action: null,
  });
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");

  const [querySearch, setQuerySearch] = useState("");
  useDebouncedEffect(
      () => {
        setQuerySearch(search);
        setPage(0);
      },
      [search],
      700
  );


  const { data, isLoading, isFetching } = useGetLogs({
    page,
    search: querySearch,
        ...dates
  });

  const rows = data?.data?.items || [];
  const totalPages = data?.data?.totalPages;

  const clearSearchTerm = search || dates.endDate;

  const onDiff = (row) => {
    setDiff({ ...row, open: true });
  };

  const onClose = () => {
    setDiff((previousDiff) => ({
      ...previousDiff,
      open: false,
    }));
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  return (
      <StyledLogsPageContainer>
        <Typography variant="h4">LOGS</Typography>
        <StyledTableContainer>
          <StyledTableHeaderContainer>
            <StyledUserTableSearch
                placeholder="Search by hub"
                variant="standard"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                  ),
                }}
            />
            <StyledLogsTableDatesContainer>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    label="From"
                    value={dates.startDate}
                    onChange={(startDate) => {
                      setDates({ ...dates, startDate });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    maxDate={new Date()}
                />

                <StyledDatePickerSpacer>-</StyledDatePickerSpacer>

                <DatePicker
                    disabled={!dates.startDate}
                    label="To"
                    value={dates.endDate}
                    onChange={(endDate) => {
                      setDates({ ...dates, endDate });
                        setPage(0);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    minDate={dates.startDate}
                    maxDate={new Date()}
                />
              </LocalizationProvider>
              <StyledLogsTableClearButton
                  variant="contained"
                  color="primary"
                  disabled={isFetching || isLoading || !clearSearchTerm}
                  disableRipple
                  onClick={() => {
                    setDates({ startDate: null, endDate: null });
                    setSearch('')
                      setPage(0);
                  }}
              >
                Clear
              </StyledLogsTableClearButton>
            </StyledLogsTableDatesContainer>
            <Button
                variant="contained"
                color="primary"
                disabled={!rows.length}
                onClick={() => {
                  exportLogs({ ...dates, token, page, search: querySearch });
                }}
            >
              Export Logs
            </Button>
          </StyledTableHeaderContainer>
          <DataTable
              rows={rows}
              columns={columns}
              isLoading={isLoading || isFetching}
              rowProps={{ onDiff }}
              noRowsMessage="No logs for selected dates"
              skeletonColumns={6}
              page={page}
              totalPages={totalPages}
              handleChangePage={handleChangePage}
          />
        </StyledTableContainer>
        <DiffModal {...diff} onClose={onClose} />
      </StyledLogsPageContainer>
  );
};

