import React, { useContext, useEffect } from "react";
import { useLoginState } from "./useLoginState";
import { useLocation } from "react-router-dom";

export const AuthContext = React.createContext();

AuthContext.displayName = "AuthContext";

export const AuthContextProvider = ({ children }) => {
  const { state, signIn, checkStatus, signOut } = useLoginState();

  useEffect(() => {
    if (!state.token) {
      checkStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let location = useLocation();

  useEffect(() => {
    checkStatus();
  }, [location]);

  const onFocus = () => {
    checkStatus();
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, []);

  const values = {
    signIn,
    signOut,
    ...state,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
