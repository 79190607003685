import React, {useState} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export const ConfirmationDialog = ({
  open,
  loading,
  onCancel = () => {},
  onAction = () => {},
  title,
  message,
    property,
  actionLabel = "DELETE",
  actionButtonProps = {},
}) => {
  const [values, setValues] = useState({ propertyValue: '' })
  const onValidateInput = (e) => {
    const value = e.target.value;
    if(!value) return;
    setValues({ ...values, propertyValue:  value })
  }
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
        <DialogContentText>
          {property && (
              <div className="mt-5">
                <p>
                  Please type <i><b>DELETE</b></i> to confirm.
                </p>
                <input onChange={onValidateInput} className="delete-input-confirm" />
              </div>
          )}
        </DialogContentText>


      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={onAction}
          disableRipple
          color="error"
          variant="contained"
          disabled={loading || (property && values.propertyValue !== 'DELETE')}
          {...actionButtonProps}
        >
          {actionLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
