import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";

export const RedirectToIndex = ({ children }) => {
  const { token, isChecking } = useAuthContext();

  if (token && !isChecking) {
    return <Navigate to="/" replace />;
  }

  return children;
};
