import { Grid } from "@mui/material";
import {
  StyledSectionTitle,
  StyledSectionContainerGrid,
  StyledSectionInputGroupGrid,
} from "./styles";

import TextField from "../../mui-components/TextField";
import Checkbox from "../../mui-components/Checkbox";

export const CoordinatesSection = ({ isDisabled }) => {
  return (
    <>
      <StyledSectionTitle variant="h6">Coordinates</StyledSectionTitle>
      <StyledSectionContainerGrid container spacing={4}>
        <StyledSectionInputGroupGrid item md={6} sm={12}>
          <TextField
            disabled={isDisabled}
            name="coordinates.lat"
            label="Latitude"
            variant="standard"
          />
          <TextField
            disabled={isDisabled}
            name="coordinates.lng"
            label="Longitude"
            variant="standard"
          />
        </StyledSectionInputGroupGrid>
        <StyledSectionInputGroupGrid item md={6} sm={12}>
          <TextField
            disabled={isDisabled}
            name="address"
            label="Address"
            variant="standard"
          />
        </StyledSectionInputGroupGrid>
        <Grid item xs={12}>
          <Checkbox
            disabled={isDisabled}
            name="useWebViewCheckout"
            label="Use Webview Checkout"
          />
        </Grid>
      </StyledSectionContainerGrid>
    </>
  );
};
