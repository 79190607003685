export const getFromUrl = async ({ queryKey }) => {
  const { url = "", token } = queryKey[1];
  const result = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  });
  return result.json();
};

export const logInToUrl = async (url, body) => {
  const result = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  return result.json();
};

export const postToUrl = async (url, token, body) => {
  const result = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(body),
  });
  return result.json();
};

export const putToUrl = async (url, token, body) => {
  const result = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(body),
  });
  return result.json();
};

export const patchToUrl = async (url, token, body) => {
  console.log(body);
  const result = await fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(body),
  });
  return result.json();
};

export const deleteToUrl = async (url, token, body) => {
  const result = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(body),
  });
  return result.json();
};
