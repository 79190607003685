import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";

import {
  StyledHubCard,
  StyledHubCardTitleGrid,
  StyledHubCardToggleButton,
  StyledHubCardTogglebuttonGroup,
  StyledHubCardSectionGrid,
  StyledHubCardTriggersGrid,
  StyledHubCardDivider,
  StyledHubCardTriggerValue,
} from "./styles";

import { usePermissionContext } from "../context/PermissionContext";
import { useNotificationContext } from "../context/NotificationContext";
import { useSwitchHubStateMutation } from "../sources/mutations";

function mapTriggerValue(value) {
  const splitValue = value.split(" ");
  const days = splitValue[5];
  const hour = splitValue[2].length === 1 ? `0${splitValue[2]}` : splitValue[2];
  const minutes =
    splitValue[1].length === 1 ? `0${splitValue[1]}` : splitValue[1];

  return `${days} ${hour}:${minutes}`;
}

export const HubInfo = ({ onEditETA, onHubStateChange, ...props }) => {
  const { notify } = useNotificationContext();
  const navigate = useNavigate();
  const { hasHubEditPermission, hasReadOnlyPermission, hasDeliveryAreaServingHubPermission,hasDeliveryAreaDeliveryFeePermission } =
    usePermissionContext();

  const { data, mutate: updateHub } = useSwitchHubStateMutation({
    id: props.id,
    onSuccess: ({ success }) => {
      if (success) {
        notify({
          severity: "success",
          message: "Hub state updated successfully !",
        });
      } else {
        notify({ severity: "error", message: "Something went wrong." });
      }
    },
    onError: () => {
      notify({ severity: "error", message: "Something went wrong." });
    },
  });
  const storeStatus = data?.data?.state;

  const changeHubState = (state) => {
    onHubStateChange(
      { triggers: props.triggers, id: props.id, state },
      updateHub
    );
  };

  return (
    <>
      <StyledHubCard>
        <CardContent>
          <Grid container spacing={2}>
            <StyledHubCardTitleGrid item xs={8}>
              <Typography variant="h5" color="#053f6e">
                {props.id}
              </Typography>
            </StyledHubCardTitleGrid>
            <Grid item xs={4} display="flex" justifyContent="flex-end">
              <CardActions>
                {!hasReadOnlyPermission && !hasDeliveryAreaServingHubPermission && !hasDeliveryAreaDeliveryFeePermission && (
                  <StyledHubCardTogglebuttonGroup
                    value={storeStatus || props.state}
                    exclusive
                    onChange={(_, value) => {
                      if (value) {
                        changeHubState(value);
                      }
                    }}
                  >
                    <StyledHubCardToggleButton color="primary" value="OPEN">
                      OPEN
                    </StyledHubCardToggleButton>
                    <StyledHubCardToggleButton color="error" value="CLOSED">
                      Closed
                    </StyledHubCardToggleButton>
                  </StyledHubCardTogglebuttonGroup>
                )}
                {(!hasReadOnlyPermission && !hasDeliveryAreaServingHubPermission && !hasDeliveryAreaDeliveryFeePermission) &&
                  (hasHubEditPermission ? (
                    <Button
                      variant="contained"
                      disableRipple
                      size="large"
                      onClick={() => {
                        navigate(`/hub/${props.id}`);
                      }}
                    >
                      Edit
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      disableRipple
                      size="large"
                      onClick={() => onEditETA(props)}
                    >
                      Edit ETA
                    </Button>
                  ))}
                {hasReadOnlyPermission && (
                  <Button
                    variant="contained"
                    disableRipple
                    size="large"
                    onClick={() => {
                      navigate(`/hub/${props.id}`);
                    }}
                  >
                    More Info
                  </Button>
                )}
              </CardActions>
            </Grid>

            <StyledHubCardSectionGrid item xs={3}>

              <Typography fontWeight="600" variant="subtitle1">
                Time Zone
              </Typography>
              <Typography color="grey.700">{props.timeZone}</Typography>

              <Typography fontWeight="600" variant="subtitle1">
                Address
              </Typography>
              <Typography color="grey.700">{props.address}</Typography>
            </StyledHubCardSectionGrid>

            <StyledHubCardSectionGrid item xs={3}>
              <Typography fontWeight="600" variant="subtitle1">
                Coordinates
              </Typography>
              <Typography color="grey.700">
                lat: {props.coordinates?.lat}
                <br />
                long: {props.coordinates?.lng}
              </Typography>

              <Typography fontWeight="600" variant="subtitle1">
                Use Webview Checkout
              </Typography>
              <Typography color="grey.700">
                {props.useWebViewCheckout.toString()}
              </Typography>
            </StyledHubCardSectionGrid>

            <StyledHubCardSectionGrid item xs={3}>
              <Typography fontWeight="600" variant="subtitle1">
                Tips Enabled
              </Typography>
              <Typography color="grey.700">
                {props.configuration?.tipsEnabled?.toString()}
              </Typography>

              <Typography fontWeight="600" variant="subtitle1">
                Order Minimum Amount
              </Typography>
              <Typography color="grey.700">
                {props.configuration?.orderMinAmount}
              </Typography>
            </StyledHubCardSectionGrid>
            <Grid item xs={3}>
              <Typography fontWeight="600" variant="subtitle1">
                ETA Value
              </Typography>
              <Typography color="grey.700">{props.etaValue?.value}</Typography>

              <Typography fontWeight="600" variant="subtitle1">
                ETA Reason
              </Typography>
              <Typography color="grey.700">
                {props.etaReason?.type} - {props.etaReason?.comment}
              </Typography>
            </Grid>
          </Grid>
          {!!props?.triggers?.length && <StyledHubCardDivider flexItem />}
          <StyledHubCardTriggersGrid container spacing={2}>
            {!!props?.triggers?.length && (
              <>
                <Grid item xs>
                  <Typography fontWeight="600" variant="subtitle1">
                    Triggers
                  </Typography>
                </Grid>
                <Grid item xs={11}>
                  {props?.triggers?.map((trigger, index) => (
                    <StyledHubCardTriggerValue key={index} component="span">
                      <Typography component="span">{trigger.type} </Typography>
                      {" - "}
                      <Typography component="span" color="grey.700">
                        {trigger?.value && mapTriggerValue(trigger.value)}
                      </Typography>
                    </StyledHubCardTriggerValue>
                  ))}
                </Grid>
              </>
            )}
          </StyledHubCardTriggersGrid>
        </CardContent>
      </StyledHubCard>
    </>
  );
};
