import styled from "styled-components";
import { Link as LogoLink } from "react-router-dom";
import {
  Container,
  TableContainer,
  Typography,
  TextField,
  AppBar,
  Toolbar,
  Card,
  CardContent,
  Box,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
  Button,
} from "@mui/material";

// TABLE

export const StyledNoRowsMessageContainer = styled(Container)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 492px;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  && {
    max-height: 440px;
    min-height: 440px;
  }
`;

// SEARCH HEADER

export const StyledSearchHeaderContainer = styled(Container)`
  && {
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: space-between;
  }
`;



export const StyledSearchHeaderTitle = styled(Typography)`
  && {
    margin-right: 16px;
  }
`;

export const StyledSearchInput = styled(TextField)`
  && {
    width: 250px;
    margin-right: 16px;
  }
`;

// NAVIGATION

export const StyledAppBar = styled(AppBar)`
  && {
    background-color: #053f6f;
  }
`;

export const StyledNavigationContainer = styled(Container)`
  && {
    padding: 0 16px;
  }
`;

export const StyledToolbar = styled(Toolbar)`
  && {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const StyledLogoLink = styled(LogoLink)`
  && {
    color: white;
    text-decoration: none;
  }
`;

export const StyledLogoSubTitle = styled.span`
  font-family: "Inter-SemiBold";
  font-size: 18px;
  margin: 0 8px 0 8px;
`;

export const StyledLeftSideLinksConatiner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 32px;
`;

export const StyledRightSideLinksConatiner = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
`;

export const StyledUserMenuButton = styled(Button)`
  && {
    color: white;
    text-transform: none;
    font-size: 16px;
    padding: 4px 12px;
  }
`;

// LOCATION CARD

export const StyledLocationCard = styled(Card)`
  && {
    display: flex;
    cursor: pointer;
    height: 140px;
    width: 140px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
`;

export const StyledLocationCardBox = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledLocationCardContent = styled(CardContent)`
  && {
    flex: 1 0 auto;
  }
`;

// HUB INFO

export const StyledHubCard = styled(Card)`
  && {
    margin: 16px 0;
    border-radius: 10px;
  }
`;

export const StyledHubCardTitleGrid = styled(Grid)`
  && {
    display: flex;
    align-items: center;
  }
`;

export const StyledHubCardTogglebuttonGroup = styled(ToggleButtonGroup)`
  && {
    margin-right: 16px;
  }
`;

export const StyledHubCardToggleButton = styled(ToggleButton)`
  && {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

export const StyledHubCardSectionGrid = styled(Grid)`
  && {
    border-right: 0.1em solid rgba(0, 0, 0, 0.12);
    padding-right: 0.5em;
    box-sizing: border-box;
  }
`;

export const StyledHubCardTriggersGrid = styled(Grid)`
  && {
    margin-top: 0px;
    display: flex;
    align-items: baseline;
  }
`;

export const StyledHubCardDivider = styled(Divider)`
  && {
    margin-top: 16px;
  }
`;

export const StyledHubCardTriggerValue = styled(Typography)`
  && {
    margin-right: 8px;
  }
`;
