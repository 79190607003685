import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

const CheckboxWrapper = ({ name, label, disabled }) => {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      style={{ margin: 0 }}
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Checkbox
              onChange={(e) => field.onChange(e.target.checked)}
              checked={field.value}
              disabled={disabled}
            />
          )}
        />
      }
      label={label}
      labelPlacement="start"
      disabled={disabled}
    />
  );
};

export default CheckboxWrapper;
