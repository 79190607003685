import { Navigate, useLocation, useParams } from "react-router-dom";
import { usePermissionContext } from "../../context/PermissionContext";
import { useAuthContext } from "../../context/AuthContext";

export const RoutePermission = ({ children }) => {
  const { isChecking } = useAuthContext();
  const {
    availablePlaces,
    hasHubEditPermission,
    hasReadOnlyPermission,
    hasAdminPermission,
    hasManagerPermission
  } = usePermissionContext();
  const { pathname } = useLocation();
  const { code } = useParams();

  const isCountryURL = pathname.includes("/country/");
  const hasCountryPermission = availablePlaces?.availableCountries?.find(
    (el) => el.code.toLowerCase() === code?.toLowerCase()
  );

  const isCityURL = pathname.includes("/city/");
  const hasCityPermission = availablePlaces?.availableCities?.find(
    (el) => el.code.toLowerCase() === code?.toLowerCase()
  );

  const isEditPath = pathname.includes("/hub/");
  const isUsersPath = pathname.includes("/users");
  const isLogsPath = pathname.includes("/logs");

  if (isCountryURL && !hasCountryPermission && !isChecking) {
    return <Navigate to="/" replace />;
  }

  if (isCityURL && !hasCityPermission && !isChecking) {
    return <Navigate to="/" replace />;
  }

  if (
    isEditPath &&
    !hasHubEditPermission &&
    !hasReadOnlyPermission &&
    !isChecking
  ) {
    return <Navigate to="/" replace />;
  }

  if (isUsersPath && !hasAdminPermission && !isChecking) {
    return <Navigate to="/" replace />;
  }

  console.log(!hasAdminPermission || !hasManagerPermission, hasManagerPermission, hasAdminPermission);
  console.log(isLogsPath && !isChecking && (!hasAdminPermission || !hasManagerPermission))
  if (isLogsPath && !isChecking && !(hasAdminPermission || hasManagerPermission)) {
    return <Navigate to="/" replace />;
  }

  return children;
};
