import { useState } from "react";
import { Button, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  StyledTableContainer,
  StyledTableHeaderContainer,
  StyledUsersPageContainer,
  StyledUserPageHeader,
  StyledUserTableSearch,
} from "../styles";

import { useGetUsersQuery } from "../../sources/queries";
import { useDeleteUserMutation } from "../../sources/mutations";
import { DataTable } from "../../components/Table";
import { ConfirmationDialog } from "../../components/modals/ConfirmationDialog";
import { UserModal } from "../../components/modals/UserModal";

import { columns } from "./tableConfig";
import { useDebouncedEffect } from "../../utilities/useDebouncedEffect";

export const UsersPage = () => {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [querySearch, setQuerySearch] = useState("");
  useDebouncedEffect(
    () => {
      setQuerySearch(search);
      setPage(0);
    },
    [search],
    700
  );

  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    userId: null,
  });
  const [userModal, setUserModal] = useState({
    open: false,
    isEditMode: false,
    userId: undefined,
  });

  const { mutate: deleteUser, isLoading: isDeletingUser } =
    useDeleteUserMutation({
      userId: deleteDialog.userId,
      onSuccess: () => {
        setDeleteDialog({
          open: false,
          userId: null,
        });
      },
    });

  const { data, isLoading } = useGetUsersQuery({
    page,
    search: querySearch,
  });

  const rows = data?.data?.items || [];
  const totalPages = data?.data?.totalPages;

  const onCancel = () => {
    setDeleteDialog({
      open: false,
      userId: null,
    });
  };

  const onDelete = (userId) => {
    setDeleteDialog({
      open: true,
      userId,
    });
  };

  const onConfirmDelete = () => {
    deleteUser();
  };

  const onEdit = (userId) => {
    setUserModal({
      open: true,
      isEditMode: true,
      userId,
    });
  };

  const onCancelEdit = () => {
    setUserModal({
      open: false,
      isEditMode: false,
    });
  };

  const onAdd = () => {
    setUserModal({
      open: true,
      isEditMode: false,
      userId: undefined,
    });
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  return (
    <StyledUsersPageContainer>
      <StyledUserPageHeader variant="h4">USERS</StyledUserPageHeader>
      <StyledTableContainer>
        <StyledTableHeaderContainer>
          <StyledUserTableSearch
            placeholder="Search by name"
            variant="standard"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" color="primary" onClick={onAdd}>
            Add User
          </Button>
        </StyledTableHeaderContainer>
        <DataTable
          rows={rows}
          columns={columns}
          isLoading={isLoading}
          page={page}
          totalPages={totalPages}
          handleChangePage={handleChangePage}
          rowProps={{ onEdit, onDelete }}
          noRowsMessage="No users found matching this search"
        />
      </StyledTableContainer>
      <ConfirmationDialog
        open={deleteDialog.open}
        loading={isDeletingUser}
        onCancel={onCancel}
        onAction={onConfirmDelete}
        title={"Delete this user?"}
        message={"Are you sure you want to delete this user?"}
      />
      <UserModal {...userModal} onCancel={onCancelEdit} />
    </StyledUsersPageContainer>
  );
};
