import { useMemo } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import { StyledNoRowsMessageContainer, StyledTableContainer } from "./styles";

import { TableSkeleton } from "./placeholders/TableSkeleton";

export const DataTable = ({
  rows,
  columns,
  isLoading,
  page,
  totalPages,
  handleChangePage,
  rowProps = {},
  noRowsMessage,
  showPagination = true,
  skeletonColumns,
}) => {
  const tableRows = useMemo(() => rows.map((row, index) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        {columns.map((column) => {
          const value = row[column.id];
          const formatValue = {
            row,
            ...rowProps,
          };
          return (
            <TableCell key={column.id} align={column.align}>
              {column.format ? column.format(formatValue) : value}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }), [rows]);

  if (!rows.length && !isLoading) {
    return (
      <StyledNoRowsMessageContainer>
        <Typography variant="h4" color="grey.500">
          {noRowsMessage}
        </Typography>
      </StyledNoRowsMessageContainer>
    );
  }

  return (
    <>
      <StyledTableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    width: column.width,
                  }}
                >
                  <Typography fontWeight={600} fontSize={14}>
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <TableSkeleton columns={skeletonColumns} />}
            {tableRows}
          </TableBody>
        </Table>
      </StyledTableContainer>
      {showPagination && (
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={totalPages}
          labelDisplayedRows={({ page, count }) =>
            isLoading || !rows.length ? "" : `Page ${page + 1} of ${count}`
          }
          page={page}
          onPageChange={handleChangePage}
          nextIconButtonProps={{
            disabled: totalPages === page + 1 || isLoading || !rows.length,
          }}
        />
      )}
    </>
  );
};
