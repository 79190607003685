import { useState, useCallback, useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Polygon,
} from "@react-google-maps/api";

import { useGetMapInfo } from "../../sources/queries";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 19.43723975893789,
  lng: -99.18500233500642,
};

export const MapPage = () => {
  const [map, setMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBbs_i07TFhYwb-fFGNeqn-aT9iu2wKd1c",
  });

  const { data } = useGetMapInfo();
  const { hubLabels = [], hubPolygons = [] } = data || {};

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const fitToBounds = () => {
    const bounds = new window.google.maps.LatLngBounds();
    hubLabels.forEach(({ coordinates }) => {
      bounds.extend(coordinates);
    });
    map.fitBounds(bounds);
  };

  useEffect(() => {
    if (map) {
      fitToBounds();
    }
  }, [hubLabels, map]);

  if (!isLoaded) {
    return null;
  }

  return (
    <GoogleMap
      onLoad={onLoad}
      mapContainerStyle={containerStyle}
      center={center}
      zoom={9}
    >
      <>
        {hubLabels.map(({ id, coordinates }, index) => (
          <Marker key={index} label={id} position={coordinates} />
        ))}
        {hubPolygons.map(({ outer, inner }, index) => {
          var allPolygons = [];
          var resultOuterArray = [];
          for (var j = 0; j < outer.length; j++) {
            resultOuterArray.push({ lat: outer[j][0], lng: outer[j][1] });
          }
          allPolygons.push(resultOuterArray);
          for (var k = 0; k < inner.length; k++) {
            var innerPolygon = inner[k];
            var resultInnerArray = [];
            for (var z = 0; z < innerPolygon.length; z++) {
              resultInnerArray.push({
                lat: innerPolygon[z][0],
                lng: innerPolygon[z][1],
              });
            }
            allPolygons.push(resultInnerArray);
          }

          return (
            <Polygon
              key={index}
              paths={allPolygons}
              options={{ fillColor: "red", fillOpacity: 0.2 }}
            />
          );
        })}
      </>
    </GoogleMap>
  );
};
