export const getEnv = (variable) => {
  let domain = window?.location?.origin;

  if (domain?.includes("ff")) {
    return process.env[`${variable}_FF`] ?? "";
  }

  if (domain?.includes("prd")) {
    return process.env[`${variable}_PRD`] ?? "";
  }

  return process.env[`${variable}_DEV`];
};

export const getEnvironmentLabel = () => {
  let domain = window?.location?.origin;
  if (domain?.includes("ff")) {
    return "FF";
  }

  if (domain?.includes("prd")) {
    return "PRD";
  }

  return "SBX";
};
