export const mockUser = {
  username: "user",
  roles: [
    // {
    //   id: 1,
    //   code: "ADMIN",
    //   name: "Admin",
    // },
    {
      id: 3,
      code: "PRN-MANAGER",
      name: "Pristina Manger",
    },
    {
      id: 4,
      code: "BOG-MANAGER",
      name: "Bogota Manager",
    },
    {
      id: 5,
      code: "CO-MANAGER",
      name: "Colombia Manager",
    },
    {
      id: 6,
      code: "XK-MANAGER",
      name: "Kosovo Manager",
    },
  ],
};

export const getAvailablePlaces = (user) => {
  if (!user) {
    return undefined;
  }

  return user.roles?.reduce(
    (acc, role) => {
      const isCity = ({ code }) => code.split("-")[0].length === 3;
      const isCountry = ({ code }) => code.split("-")[0].length === 2;

      const mapNewPermission = (info) => ({
        code: info.code.split("-")[0],
        name: info.name.split(" ")[0],
        role: info.code.split("-")[1],
      });

      const newRole = mapNewPermission(role);
      return {
        ...acc,
        availableCountries: isCountry(newRole)
          ? [...acc.availableCountries, newRole]
          : acc.availableCountries,
        availableCities: isCity(newRole)
          ? [...acc.availableCities, newRole]
          : acc.availableCities,
      };
    },
    { availableCities: [], availableCountries: [] }
  );
};

export const getPermissions = (user) => {
  const roles = user?.roles?.map(({ code }) => code);

  const hasAdminPermission = roles?.includes("ADMIN");
  const hasDeliveryAreaServingHubPermission = roles?.includes("DA_SERVING_HUB");
  const hasDeliveryAreaDeliveryFeePermission = roles?.includes("DA_DELIVERY_FEE");
  const hasManagerPermission = roles?.includes("MANAGER");
  const hasHubEditPermission =
    roles?.includes("ADMIN") || roles?.includes("MANAGER");
  const hasHubCreatePermission =
    roles?.includes("ADMIN") || roles?.includes("MANAGER");
  const hasEditETAPermission =
    !hasHubEditPermission &&
    roles?.findIndex((element) => element.includes("-MANAGER")) !== -1;

  const hasReadOnlyPermission =
    roles?.findIndex((element) =>
      ["RESTRICTED", "INTEGRATION", "MOBILE-APP", "VERSION"].includes(element)
    ) !== -1;

  const hasAdminOrSupervisorPermission =  roles?.includes("ADMIN") || roles?.includes("SUPERVISOR");

  const availablePlaces = hasEditETAPermission
    ? getAvailablePlaces(user)
    : undefined;

  if (!user) {
    return {
      hasHubEditPermission: false,
      hasHubCreatePermission: false,
      hasAdminPermission: false,
      hasEditETAPermission: false,
      hasReadOnlyPermission: false,
      availablePlaces: undefined,
      hasCloseHubsByCityPermission: false,
      hasDeliveryAreaServingHubPermission: false,
      hasDeliveryAreaDeliveryFeePermission: false,
      hasAdminOrSupervisorPermission: false
    };
  }

  return {
    hasHubEditPermission,
    hasHubCreatePermission,
    hasAdminPermission,
    hasEditETAPermission,
    hasReadOnlyPermission,
    availablePlaces,
    hasManagerPermission,
    hasAdminOrSupervisorPermission,
    hasDeliveryAreaServingHubPermission,
    hasDeliveryAreaDeliveryFeePermission
  };
};
