import { Button } from "@mui/material";

export const columns = [
  { id: "username", label: "Name", minWidth: 100 },
  {
    id: "date",
    label: "Date",
    minWidth: 200,
    format: ({ row }) => {
      return new Date(row.date).toUTCString();
    },
  },
  {
    id: "action",
    label: "Action",
    minWidth: 80,
  },
  {
    id: "message",
    label: "Message",
    minWidth: 250,
  },
  {
    id: "module",
    label: "Module",
    minWidth: 100,
    format: ({ row }) => {
      const { module, moduleId } = row;
      return `${module} - ${moduleId}`;
    },
  },
  {
    id: "edit",
    width: 100,
    format: ({ row, onDiff = () => {} }) => {
      return (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => onDiff(row)}
        >
          Show Diff
        </Button>
      );
    },
  },
];
