import React from "react";
import { Select, FormHelperText } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { resolveFormObjects } from "../../utilities/resolveFormObjects";

const SelectWrapper = ({ name, children, ...otherProps }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const configSelect = {
    ...otherProps,
  };

  const error = resolveFormObjects(name, errors);
  const helperText = error?.message;

  if (name && error) {
    configSelect.error = true;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          <Select {...field} {...configSelect}>
            {children}
          </Select>
          {configSelect.error && (
            <FormHelperText error>{helperText}</FormHelperText>
          )}
        </>
      )}
    />
  );
};

export default SelectWrapper;
