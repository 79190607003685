import React, {useState} from 'react';
import {uploadDeliveryAreasWithCsv} from "../../sources/mutations";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {useAuthContext} from "../../context/AuthContext";

export const UpdateDeliveryAreaWithCSV = ({
  open,
  onCancel = () => {},
  title,
  message,
}) => {
  const { token } = useAuthContext();
  const [file, setFile] = useState({ file: '' })
  const handleFile = (e) => {
    const value = e.target.files;
    if(!value) return;
    setFile({ ...file, file:value[0] })
  }


  const onAction = () => {
    console.log(file);
    if (! (file && file.file)) return;
    uploadDeliveryAreasWithCsv({token: token, file: file?.file})
    setTimeout(function() {
      onCancel();
    }, 1000);
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
        <DialogContentText>
                <input className="mt-5" type="file" onChange={handleFile} />
        </DialogContentText>


      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={onAction}
          disableRipple
          color="primary"
          variant="contained"
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};
