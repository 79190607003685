import {useGetDeliveryAreaQuery, useGetHubIdsQuery} from "../../sources/queries";
import {useEditDeliveryAreaHubIdMutation} from "../../sources/mutations";
import {useNotificationContext} from "../../context/NotificationContext";
import {Button, Dialog, DialogActions, DialogTitle, FormControl, Grid, InputLabel} from "@mui/material";
import {StyledFormModalDialogContent, StyledFormModalGridContainer, StyledFormModalMenuItem} from "./styles";
import {FormProvider, useForm} from "react-hook-form";
import Select from "../mui-components/Select";

export default function EditDeliveryAreaServingHub({ open, setOpen, hubInfo }) {
    const { notify } = useNotificationContext();
    const { refetch } = useGetDeliveryAreaQuery({ cacheTime: 0, search: null });

    const { data } = useGetHubIdsQuery();
    const hubIds = data || [];

    const { mutate } = useEditDeliveryAreaHubIdMutation({
        onSuccess: ({ success, message }) => {
            if (success) {
                setOpen(false);
                notify({
                    severity: "success",
                    message: "Hub Id has been updated successfully !",
                });
                refetch();
            } else {
                notify({ severity: "error", message });
            }
        },
        onError: () => {
            notify({ severity: "error", message: "Something went wrong." });
        },
    });

    const updateHubId = async (state) => {
        mutate({ id: hubInfo.id, hubId: state.hubId});
    };

    const methods = useForm({
        defaultValues: {
            hubId: '',
        }
    });

    const closeServingHub = () => {
        setOpen(false);
    };

    return (
        <Dialog fullWidth open={open} onClose={closeServingHub}>
            <DialogTitle>{ "Edit Serving hub"}</DialogTitle>
            <StyledFormModalDialogContent dividers>
                <FormProvider {...methods}>
                    <StyledFormModalGridContainer container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="state-select-label">Hub Id</InputLabel>
                                <Select
                                    name="hubId"
                                    labelId="state-select-label"
                                    label="Hub Id">
                                    {hubIds?.map((hubId) => (
                                        <StyledFormModalMenuItem key={hubId} value={hubId}>
                                            {hubId}
                                        </StyledFormModalMenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </StyledFormModalGridContainer>
                </FormProvider>
            </StyledFormModalDialogContent>
            <DialogActions>
                <Button onClick={closeServingHub}>Cancel</Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={methods.handleSubmit(updateHubId)}
                >
                    Update serving hub
                </Button>
            </DialogActions>
        </Dialog>
    );
}