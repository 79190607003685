import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const requiredText = "This field is required";

export const createSchema = yupResolver(
  yup.object().shape({
    username: yup.string().required(requiredText),
    password: yup.string().required(requiredText),
    roles: yup.array().min(1, "User must have at leas one role"),
  })
);

export const editSchema = yupResolver(
  yup.object().shape({
    username: yup.string().required(requiredText),
    password: yup.string(),
    roles: yup.array().min(1, "User must have at leas one role"),
  })
);
