import {
  StyledSectionTitle,
  StyledSectionContainerGrid,
  StyledSectionInputGroupGrid,
} from "./styles";

import TextField from "../../mui-components/TextField";

export const IntercomSection = ({ isDisabled }) => {
  return (
    <>
      <StyledSectionTitle variant="h6">Intercom</StyledSectionTitle>
      <StyledSectionContainerGrid container spacing={4}>
        <StyledSectionInputGroupGrid item md={6} sm={12}>
          <TextField
            disabled={isDisabled}
            name="intercom.androidApiKey"
            label="Android API Key"
            variant="standard"
          />
          <TextField
            disabled={isDisabled}
            name="intercom.iosApiKey"
            label="iOS API Key"
            variant="standard"
          />
        </StyledSectionInputGroupGrid>
        <StyledSectionInputGroupGrid item md={6} sm={12}>
          <TextField
            disabled={isDisabled}
            name="intercom.androidId"
            label="Android ID"
            variant="standard"
          />
          <TextField
            disabled={isDisabled}
            name="intercom.iosId"
            label="iOS ID"
            variant="standard"
          />
        </StyledSectionInputGroupGrid>
      </StyledSectionContainerGrid>
    </>
  );
};
