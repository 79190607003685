import { useReducer } from "react";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";

export const initialState = {
  isChecking: true,
  token: null,
  user: null,
};

const loginReducer = (prevState, action) => {
  switch (action.type) {
    case "RESTORE_TOKEN":
      return {
        ...prevState,
        token: action.token,
        user: action.user,
        isChecking: false,
      };
    case "SIGN_IN":
      return {
        ...prevState,
        token: action.token,
        user: action.user,
        isChecking: false,
      };
    case "SIGN_OUT":
      return {
        ...prevState,
        token: null,
        user: null,
        isChecking: false,
      };
    default:
      return prevState;
  }
};

export const useLoginState = () => {
  const [state, dispatch] = useReducer(loginReducer, initialState);

  const signIn = async ({ token }) => {
    localStorage.setItem("token", token);
    dispatch({ type: "SIGN_IN", token, user: jwt_decode(token) });
  };

  const checkStatus = async () => {
    const token = localStorage.getItem("token");
    const user = token ? jwt_decode(token) : null;
    const time = parseInt(Date.now() / 1000);

    if (token && time > user?.exp) {
      return dispatch({ type: "SIGN_OUT", token: null, user: null });
    }

    if (!token) {
      return dispatch({ type: "RESTORE_TOKEN", token: null, user: null });
    }

    dispatch({ type: "RESTORE_TOKEN", token, user });
  };

  const signOut = () => {
    localStorage.removeItem("token");
    Cookies.remove('X-AUTH-COOKIE');
    dispatch({ type: "SIGN_OUT", token: null, user: null });
  };

  return {
    state,
    signIn,
    checkStatus,
    signOut,
  };
};
