import { useState } from "react";
import { useParams } from "react-router-dom";

import { StyledHubsPageContainer } from "../styles";

import { usePermissionContext } from "../../context/PermissionContext";
import { useGetCountryDeliveryAreasQuery } from "../../sources/queries";

import { CloseReasonDialog } from "../../components/modals/CloseReasonDialog";
import { SearchHeader } from "../../components/SearchHeader";
import { DeliveryAreaInfo } from "../../components/DeliveryAreaInfo";
import { HubInfoSkeleton } from "../../components/placeholders/HubInfoSkeleton";
import { NoDeliveryArea } from "./NoDeliveryArea";
import EditDeliveryAreaEtaModal from "../../components/modals/EditDeliveryAreaEtaModal";

export const CountryDeliveryAreas = () => {
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [hubInfo, setHubInfo] = useState({});
  const [openReason, setOpenReason] = useState(false);
  const [hubStatus, setHubStatus] = useState(null);

  const { hasEditETAPermission } = usePermissionContext();

  const onEditETA = (info) => {
    setOpen(true);
    setHubInfo(info);
  };
  const { code } = useParams();
  const { data, isLoading } = useGetCountryDeliveryAreasQuery({
    refetchOnWindowFocus: false,
    cacheTime: 0,
    search,
  });
  const hubs = data?.data || [];

  const onCancel = () => {
    setOpenReason(false);
  };

  const onAction = ({ message }) => {
    const { variables, mutation } = hubStatus;
    mutation({ ...variables, message });
    setOpenReason(false);
  };

  const onDeliveryAreaStateChange = (variables, mutation) => {
    if (variables?.state === "OPEN") {
      mutation(variables);
    } else {
      setOpenReason(true);
      setHubStatus({ variables, mutation });
    }
  };

  return (
    <StyledHubsPageContainer maxWidth="lg">
      <SearchHeader
        header={`${code?.toUpperCase()} DELIVERY AREA`}
        setSearch={setSearch}
      />
      <NoDeliveryArea
        hubs={hubs}
        isLoading={isLoading}
        message={`No hubs available for ${code?.toUpperCase()}`}
        showLink
      />
      {isLoading && (
        <>
          <HubInfoSkeleton />
          <HubInfoSkeleton />
        </>
      )}

      {hubs?.map((hub) => {
        return (
          <DeliveryAreaInfo
            key={hub.id}
            {...hub}
            onEditETA={onEditETA}
            onDeliveryAreaStateChange={onDeliveryAreaStateChange}
          />
        );
      })}
      {hasEditETAPermission && (
        <EditDeliveryAreaEtaModal open={open} setOpen={setOpen} hubInfo={hubInfo} />
      )}
      <CloseReasonDialog
        open={openReason}
        onCancel={onCancel}
        onAction={onAction}
        title={`Close ${hubStatus?.variables?.id} delivery area?`}
        message="If you sure you want to close this delivery area please choose the reason for closing."
        actionLabel="Close Hub"
        actionButtonProps={{ color: "primary" }}
      />
    </StyledHubsPageContainer>
  );
};
