import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useForm, FormProvider } from "react-hook-form";
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import {
  StyledFormModalDialogContent,
  StyledFormModalGridContainer,
  StyledFormModalMenuItem,
} from "./styles";
import Select from "../mui-components/Select";

import { useNotificationContext } from "../../context/NotificationContext";
import { useGetETAQuery } from "../../sources/queries";
import { usePatchDeliveryAreaMutation } from "../../sources/mutations";

export default function EditDeliveryAreaEtaModal({ open, setOpen, hubInfo }) {
  const queryClient = useQueryClient();
  const methods = useForm();
  const { notify } = useNotificationContext();
  const { data: eta, isLoading } = useGetETAQuery();

  const { mutate } = usePatchDeliveryAreaMutation({
    onSuccess: ({ success, message }) => {
      if (success) {
        queryClient.invalidateQueries("getCountryDeliveryAreas");
        queryClient.invalidateQueries("getCityDeliveryAreas");
        setOpen(false);
        notify({
          severity: "success",
          message: "ETA updated successfully !",
        });
      } else {
        notify({ severity: "error", message });
      }
    },
    onError: () => {
      notify({ severity: "error", message: "Something went wrong." });
    },
  });

  const updateETA = async (state) => {
    mutate({ id: hubInfo.id, ...state });
  };

  useEffect(() => {
    if (hubInfo?.etaValue) {
      methods.reset({
        etaValue: {
          id: parseInt(hubInfo.etaValue.id),
        }
      });
    }
    if (hubInfo?.etaReason) {
      methods.reset({
        etaReason: {
          id: parseInt(hubInfo.etaReason.id),
        }
      });
    }
  }, [hubInfo]);

  if (isLoading || !eta) return null;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>Edit ETA</DialogTitle>
      <StyledFormModalDialogContent dividers>
        <FormProvider {...methods}>
          <StyledFormModalGridContainer container spacing={2}>
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="state-select-label">ETA Value</InputLabel>
                <Select
                  name="etaValue.id"
                  labelId="state-select-label"
                  label="ETA Value"
                >
                  <StyledFormModalMenuItem value={null}>
                    <em>None</em>
                  </StyledFormModalMenuItem>
                  {eta?.etaValues?.map((etaValue) => (
                    <MenuItem key={etaValue.id} value={etaValue.id}>
                      {etaValue.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="state-select-label">ETA Reason</InputLabel>
                <Select
                  name="etaReason.id"
                  labelId="state-select-label"
                  label="ETA Reason"
                >
                  <StyledFormModalMenuItem value={null}>
                    <em>None</em>
                  </StyledFormModalMenuItem>
                  {eta?.etaReasons?.map((etaReason) => (
                    <StyledFormModalMenuItem
                      key={etaReason.id}
                      value={etaReason.id}
                    >
                      {etaReason.type} / {etaReason.comment}
                    </StyledFormModalMenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </StyledFormModalGridContainer>
        </FormProvider>
      </StyledFormModalDialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={methods.handleSubmit(updateETA)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
