import { Grid, FormControl, InputLabel, MenuItem } from "@mui/material";

import {
  StyledSectionTitle,
  StyledSectionContainerGrid,
  StyledSectionMenuItem,
} from "./styles";

import { useGetETAQuery } from "../../../sources/queries";

import Select from "../../mui-components/Select";

export const ETAConfigurationSection = ({ isDisabled }) => {
  const { data: eta, isLoading } = useGetETAQuery();

  if (isLoading || !eta?.etaValues) return null;

  return (
    <>
      <StyledSectionTitle variant="h6">ETA Configuration</StyledSectionTitle>
      <StyledSectionContainerGrid container spacing={2}>
        <Grid item xs={4}>
          <FormControl variant="standard" fullWidth>
            <InputLabel disabled={isDisabled} id="state-select-label">
              ETA Value
            </InputLabel>
            <Select
              disabled={isDisabled}
              name="etaValue.id"
              labelId="state-select-label"
              label="ETA Value"
            >
              {eta?.etaValues?.map((etaValue) => (
                <MenuItem key={etaValue.id} value={etaValue.id}>
                  {etaValue.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={8}>
          <FormControl variant="standard" fullWidth>
            <InputLabel disabled={isDisabled} id="state-select-label">
              ETA Reason
            </InputLabel>
            <Select
              disabled={isDisabled}
              name="etaReason.id"
              labelId="state-select-label"
              label="ETA Reason"
            >
              {eta?.etaReasons?.map((etaReason) => (
                <StyledSectionMenuItem key={etaReason.id} value={etaReason.id}>
                  {etaReason.type} / {etaReason.comment}
                </StyledSectionMenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </StyledSectionContainerGrid>
    </>
  );
};
