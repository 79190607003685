import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { AuthContextProvider } from "./context/AuthContext";
import { PermissionContextProvider } from "./context/PermissionContext";
import { NotificationContextProvider } from "./context/NotificationContext";
import { ScrollToTop } from "./utilities/ScrollToTop";

import App from "./App";
import "./index.css";

import reportWebVitals from "./reportWebVitals";

const theme = createTheme({});

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ScrollToTop>
            <AuthContextProvider>
              <PermissionContextProvider>
                <NotificationContextProvider>
                  <App />
                </NotificationContextProvider>
              </PermissionContextProvider>
            </AuthContextProvider>
          </ScrollToTop>
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
