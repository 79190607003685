import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const requiredText = "Please select a reason.";

export const closingReasonSchema = yupResolver(
  yup.object().shape({
    message: yup.string().required(requiredText),
  })
);

export const closingReasons = [
  {
    id: 1,
    message: "Demand peak - not enough riders to deliver",
  },
  {
    id: 2,
    message: "Riders are absent or late to their shift.",
  },
  {
    id: 3,
    message: "Store Manager is late and hub can't start the shift",
  },
  {
    id: 4,
    message: "Door lock problems",
  },
  {
    id: 5,
    message: "Electrical power issues",
  },
  {
    id: 6,
    message: "Internet connectivity issues",
  },
  {
    id: 7,
    message: "Computer or printer are not working",
  },
  {
    id: 8,
    message:
      "JOKR Platform is not working (Customer App / Bringg / BTLR / etc)",
  },
  {
    id: 9,
    message: "Legal issues or inspection by authorities",
  },
  {
    id: 10,
    message: "Dangerous weather",
  },
  {
    id: 11,
    message: "Assault attempt",
  },
  {
    id: 12,
    message: "Public events or protests",
  },
  {
    id: 13,
    message: "Accident inside the hub (fire, flood, accident)",
  },
  {
    id: 14,
    message: "Accident outside the hub",
  },
  {
    id: 15,
    message: "Holidays",
  },
  {
    id: 16,
    message: "Other",
  },
];
