import styled from "styled-components";
import TextField from "../../components/mui-components/TextField";
import { ReactComponent as Logo } from "../../assets/stackLogo.svg";

import { Button, Grid, Paper, Typography } from "@mui/material";

export const StyledLoginPageContainer = styled.div`
  && {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledLoginForm = styled.div`
  && {
    justify-content: center;
  }
`;

export const StyledTextField = styled(TextField)`
  && {
    margin-bottom: 16px;
  }
`;

export const StyledLoginFormContainer = styled(Paper)`
  && {
    padding: 32px;
    border-radius: 10px;
    width: 300px;
  }
`;

export const StyledGridItemLogo = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
  }
`;

export const StyledGridItemInput = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
  }
`;

export const StyledGridItemButton = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
  }
`;

export const StyledLogo = styled(Logo)`
  height: 42px;
`;

export const StyledLoginFormTitle = styled(Typography)`
  && {
    display: flex;
    justify-content: center;
    color: #063f71;
  }
`;

export const StyledSignInButton = styled(Button)`
  && {
    margin-top: 5px;
  }
`;

export const StyledSignInGoogleButton = styled(Button)`
&& {
    margin-top: 5px;
background-color: #fff;
}
&&:hover {
    background-color: #fff;
    color: #fff;
}
`;
