import { Paper } from "@mui/material";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";
import {
  HubSection,
  CredentialsSection,
  IntercomSection,
  CoordinatesSection,
  ConfigurationSection,
  TriggerSection,
  PropertiesSection,
  DeliveryPropertiesSection,
  ETAConfigurationSection,
} from "./sections";

const StyledPaper = styled(Paper)`
  && {
    padding: 16px;
    border-radius: 10px;
  }
`;

export const HubForm = ({
  methods,
  disabled,
  renderFormActions = () => {},
}) => {
  return (
    <FormProvider {...methods}>
      <StyledPaper>
        <HubSection isDisabled={disabled} />

        <CredentialsSection isDisabled={disabled} />

        <IntercomSection isDisabled={disabled} />

        <CoordinatesSection isDisabled={disabled} />

        <ConfigurationSection isDisabled={disabled} />

        <ETAConfigurationSection isDisabled={disabled} />

        <TriggerSection isDisabled={disabled} />

        <PropertiesSection isDisabled={disabled} />

        <DeliveryPropertiesSection isDisabled={disabled} />
        {renderFormActions()}
      </StyledPaper>
    </FormProvider>
  );
};
