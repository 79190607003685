import { Routes, Route } from "react-router-dom";

import { RoutePermission } from "./components/routing/RoutePermission";
import { RedirectToLogin } from "./components/routing/RedirectToLogin";
import { RedirectToIndex } from "./components/routing/RedirectToIndex";

import Navigation from "./components/Navigation";
import { LoginPage } from "./pages/Login/LoginPage";
import { HubsPage } from "./pages/Hubs/HubsPage";
import { CityHubs } from "./pages/Hubs/CityHubs";
import { CountryHubs } from "./pages/Hubs/CountryHubs";

import { EditHubPage } from "./pages/EditHub/EditHubPage";
import { CreateHubPage } from "./pages/CreateHub/CreateHubPage";

import { UsersPage } from "./pages/Users/UsersPage";
import { MapPage } from "./pages/Map/MapPage";
import { DeliveryAreaPane } from "./pages/DeliveryArea/DeliveryAreaPane";
import { LogsPage } from "./pages/Logs/LogsPage";

import { useAuthContext } from "./context/AuthContext";
import LoginSuccess from "./pages/LoginSuccess/LoginSuccess";
import { DeliveryAreaEdit } from "./pages/DeliveryArea/DeliveryAreaEdit";
import { CreateDeliveryAreaPage} from "./pages/DeliveryArea/CreateDeliverAreaPage"
import { CityDeliveryAreas } from "./pages/DeliveryArea/CityDeliveryAreas";
import { CountryDeliveryAreas } from "./pages/DeliveryArea/CountryDeliveryAreas";
function App() {
  const { token, isChecking } = useAuthContext();

  return (
    <div className="App">
      {token && !isChecking && <Navigation />}
      <Routes>
        <Route
          path="/"
          element={
            <RedirectToLogin>
              <HubsPage />
            </RedirectToLogin>
          }
        />
        <Route
          path="/users"
          element={
            <RedirectToLogin>
              <RoutePermission>
                <UsersPage />
              </RoutePermission>
            </RedirectToLogin>
          }
        />
        <Route
          path="/map"
          element={
            <RedirectToLogin>
              <MapPage />
            </RedirectToLogin>
          }
        />
        <Route
          path="/delivery-area"
          element={
            <RedirectToLogin>
              <DeliveryAreaPane />
            </RedirectToLogin>
          }
        />
        <Route
          path="/createhub"
          element={
            <RedirectToLogin>
              <CreateHubPage />
            </RedirectToLogin>
          }
        />
        <Route
          path="/create-delivery-area"
          element={
            <RedirectToLogin>
              <CreateDeliveryAreaPage />
            </RedirectToLogin>
          }
        />
        <Route
          path="/hub/:id"
          element={
            <RedirectToLogin>
              <RoutePermission>
                <EditHubPage />
              </RoutePermission>
            </RedirectToLogin>
          }
        />

        <Route
          path="/delivery-area/:id"
          element={
            <RedirectToLogin>
              <RoutePermission>
                <DeliveryAreaEdit />
              </RoutePermission>
            </RedirectToLogin>
          }
        />

        <Route
          path="/country/:code"
          element={
            <RedirectToLogin>
              <RoutePermission>
                <CountryHubs />
              </RoutePermission>
            </RedirectToLogin>
          }
        />

        <Route
          path="/delivery-area/country/:code"
          element={
            <RedirectToLogin>
              <RoutePermission>
                <CountryDeliveryAreas />
              </RoutePermission>
            </RedirectToLogin>
          }
        />
        <Route
          path="/city/:code"
          element={
            <RedirectToLogin>
              <RoutePermission>
                <CityHubs />
              </RoutePermission>
            </RedirectToLogin>
          }
        />
        <Route
          path="/delivery-area/city/:code"
          element={
            <RedirectToLogin>
              <RoutePermission>
                <CityDeliveryAreas />
              </RoutePermission>
            </RedirectToLogin>
          }
        />
        <Route
          path="/login"
          element={
            <RedirectToIndex>
              <LoginPage />
            </RedirectToIndex>
          }
        />
        <Route
          path="/logs"
          element={
            <RedirectToLogin>
              <RoutePermission>
                <LogsPage />
              </RoutePermission>
            </RedirectToLogin>
          }
        />
        <Route
          path="/login-success"
          element={
            <RedirectToIndex>
              <LoginSuccess />
            </RedirectToIndex>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
