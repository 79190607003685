import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

import {
  StyledLocationCard,
  StyledLocationCardBox,
  StyledLocationCardContent,
} from "./styles";

export const DeliveryAreaLocationCard = ({ code, name }) => {
  const navigate = useNavigate();

  const navigateOnClick = () => {
    navigate(code.length === 2 ? `/delivery-area/country/${code}` : `/delivery-area/city/${code}`);
  };

  return (
    <StyledLocationCard onClick={navigateOnClick}>
      <StyledLocationCardBox>
        <StyledLocationCardContent>
          <Typography component="div" variant="h5" textAlign="center">
            {code.toUpperCase()}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {name}
          </Typography>
        </StyledLocationCardContent>
      </StyledLocationCardBox>
    </StyledLocationCard>
  );
};
