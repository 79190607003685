import { Typography, Grid, MenuItem } from "@mui/material";
import styled from "styled-components";

export const StyledSectionTitle = styled(Typography)`
  && {
    padding: 4px 16px;
  }
`;

export const StyledSectionTitleMargin = styled(Typography)`
  && {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

export const StyledSectionContainerGrid = styled(Grid)`
  && {
    padding: 16px;
  }
`;

export const StyledSectionInputGroupGrid = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledSectionAppendableContainerGrid = styled(Grid)`
  && {
    padding: 16px;
    display: flex;
    align-items: baseline;
  }
`;

export const StyledSectionMenuItem = styled(MenuItem)`
  && {
    white-space: unset;
    word-break: break-all;
  }
`;
