import { Fragment } from "react";
import { Typography, Container, Grid, Skeleton, Stack } from "@mui/material";
import {
  StyledFormSkeletonTitleGrid,
  StyledFormSkeletonPaper,
  StyledFormSkeleton,
} from "./styles";

export const FormSkeleton = () => {
  return (
    <Container maxWidth="md">
      <StyledFormSkeletonTitleGrid container spacing={4}>
        <Grid item>
          <Typography variant="h4">Edit Hub</Typography>
        </Grid>
      </StyledFormSkeletonTitleGrid>
      <StyledFormSkeletonPaper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={140}
              height={30}
            />
          </Grid>
          {Array.from(Array(2)).map((_, index) => (
            <Fragment key={index}>
              <Grid item xs={6}>
                <Stack>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={50}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={50}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={50}
                  />
                </Stack>
              </Grid>
            </Fragment>
          ))}
          <Grid item xs={12} marginTop={2}>
            <Skeleton animation="wave" variant="text" />
          </Grid>

          <Grid item xs={12}>
            <StyledFormSkeleton
              animation="wave"
              variant="rectangular"
              width={140}
              height={30}
            />
          </Grid>

          {Array.from(Array(2)).map((_, index) => (
            <Fragment key={index}>
              <Grid item xs={6}>
                <Stack>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={50}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={50}
                  />
                </Stack>
              </Grid>
            </Fragment>
          ))}

          <Grid item xs={12}>
            <StyledFormSkeleton
              animation="wave"
              variant="rectangular"
              width={140}
              height={30}
            />
          </Grid>

          {Array.from(Array(2)).map((_, index) => (
            <Fragment key={index}>
              <Grid item xs={6}>
                <Stack>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={50}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={50}
                  />
                </Stack>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </StyledFormSkeletonPaper>
    </Container>
  );
};
