import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { StyledDiffModalDialog } from "./styles";

const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const DiffModal = ({
  open = false,
  date = null,
  previousObject,
  updatedObject,
  username,
  action,
  onClose,
}) => {
  const parseData = (data) => (isJson(data) ? JSON.parse(data) : data);
  return (
    <StyledDiffModalDialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        {username} - {action} - {new Date(date).toUTCString()}
      </DialogTitle>
      <DialogContent>
        <ReactDiffViewer
          oldValue={JSON.stringify(parseData(previousObject), undefined, 4)}
          newValue={JSON.stringify(parseData(updatedObject), undefined, 4)}
          splitView={true}
          compareMethod={DiffMethod.WORDS}
          leftTitle="Before"
          rightTitle="After"
          showDiffOnly={false}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </StyledDiffModalDialog>
  );
};
