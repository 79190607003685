import { Grid } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";

import {
    StyledLoginPageContainer,
    StyledLoginForm,
    StyledTextField,
    StyledLoginFormContainer,
    StyledGridItemLogo,
    StyledLogo,
    StyledLoginFormTitle,
    StyledGridItemInput,
    StyledGridItemButton,
    StyledSignInButton, StyledSignInGoogleButton,
} from "./styles";
import './styles.css';
import {getEnv} from "../../utilities/getEnv";
import GoogleIcon from '@mui/icons-material/Google';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useLoginMutation } from "../../sources/mutations";

const requiredText = "This field is required";
const googleLoginReference = getEnv("REACT_APP_API_GOOGLE_REDIRECT");


export const schema = yupResolver(
  yup.object().shape({
    username: yup.string().trim().required(requiredText),
    password: yup.string().trim().required(requiredText),
  })
);

export const LoginPage = () => {
  const methods = useForm({ resolver: schema });
  const { mutate: login, isLoading } = useLoginMutation();

  return (
    <StyledLoginPageContainer>
      <StyledLoginForm>
        <StyledLoginFormContainer
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              methods.handleSubmit(login)();
            }
          }}
        >
          <FormProvider {...methods}>
            <Grid container spacing={2}>
              <StyledGridItemLogo item xs={12}>
                <StyledLogo />
              </StyledGridItemLogo>

              <Grid item xs={12}>
                <StyledLoginFormTitle variant="h4">
                  Sign In
                </StyledLoginFormTitle>
              </Grid>
              <StyledGridItemInput item xs={12}>
                <StyledTextField
                  fullWidth
                  name="username"
                  label="Username"
                  variant="standard"
                />
              </StyledGridItemInput>
              <StyledGridItemInput item xs={12}>
                <StyledTextField
                  fullWidth
                  name="password"
                  label="Password"
                  variant="standard"
                  type="password"
                />
              </StyledGridItemInput>
              <StyledGridItemButton item xs={12}>
                <StyledSignInButton
                  fullWidth
                  variant="contained"
                  disableRipple
                  onClick={methods.handleSubmit(login)}
                  disabled={isLoading}
                >
                  Sign In
                </StyledSignInButton>
              </StyledGridItemButton>
                <StyledGridItemButton item xs={12}>
                    <StyledSignInGoogleButton
                        fullWidth
                        variant="contained"
                        disableRipple
                        disabled={isLoading}
                    >

                        <a className="google-auth"
                           href={googleLoginReference}>
                            <div className="google-auth-wrapper">
                                <GoogleIcon/>
                                <p className="google-paragraph"> Sign in with Google</p>
                            </div>

                        </a>
                    </StyledSignInGoogleButton>
                </StyledGridItemButton>
            </Grid>
          </FormProvider>
        </StyledLoginFormContainer>
      </StyledLoginForm>
    </StyledLoginPageContainer>
  );
};
