import React from "react";
import styled from "styled-components";
import { TextField } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { resolveFormObjects } from "../../utilities/resolveFormObjects";

const StyledTextField = styled(TextField)`
  && {
    margin-bottom: 16px;
  }
`;

const TextFieldWrapper = React.memo(({ name, ...otherProps }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const configTextfield = {
    ...otherProps,
    fullWidth: true,
  };

  const error = resolveFormObjects(name, errors);

  if (name && error) {
    configTextfield.error = true;
    configTextfield.helperText = error.message;
  }

  return (
    <Controller
      render={({ field }) => (
        <StyledTextField {...field} {...configTextfield} />
      )}
      name={name}
      control={control}
    />
  );
});

export default TextFieldWrapper;
