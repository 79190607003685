import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Typography, Menu, MenuItem} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
    StyledAppBar,
    StyledNavigationContainer,
    StyledToolbar,
    StyledLogoLink,
    StyledLogoSubTitle,
    StyledLeftSideLinksConatiner,
    StyledRightSideLinksConatiner,
    StyledUserMenuButton,
} from "./styles";

import Link from "../components/mui-components/Link";
import {ReactComponent as Logo} from "../assets/logo.svg";

import {useAuthContext} from "../context/AuthContext";
import {usePermissionContext} from "../context/PermissionContext";
import {getEnvironmentLabel} from "../utilities/getEnv";

import {CloseHubsDialog} from "./modals/CloseHubsDialog";
import {UpdateDeliveryAreaWithCSV} from "./modals/UpdateDeliveryAreaWithCSV";

const ResponsiveAppBar = () => {
    const navigate = useNavigate();
    const {signOut, token, user} = useAuthContext();
    const [open, setOpen] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const {hasHubCreatePermission, hasAdminPermission, hasCloseHubsByCityPermission, hasManagerPermission, hasAdminOrSupervisorPermission} = usePermissionContext();
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [anchorElActions, setAnchorElActions] = useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleOpenActionMenu = (event) => {
        setAnchorElActions(event.currentTarget);
    }

    const handleCloseActionMenu = () => {
        setAnchorElActions(null);
    };

    const onCancelAction = () => {
        setOpen(false);
        window.location.href = '/';
    };

    return (
        <StyledAppBar position="static">
            <StyledNavigationContainer maxWidth="lg">
                <StyledToolbar>
                    <StyledLogoLink to="/">
                        <Typography variant="h6" noWrap component="div" display="flex">
                            <Logo/>
                            <StyledLogoSubTitle>
                                Hubs{" "}
                                <span style={{color: "#56E39F"}}>
                  {getEnvironmentLabel()}
                </span>
                            </StyledLogoSubTitle>
                        </Typography>
                    </StyledLogoLink>

                    <StyledLeftSideLinksConatiner>
                        <Link to={"/"}>Home</Link>
                        <Link to={"/map"}>Map</Link>
                        <Link to={"/delivery-area"}>DeliveryArea</Link>
                        {hasAdminPermission && <Link to={"/users"}>Users</Link>}
                        {(hasAdminPermission || hasManagerPermission) && <Link to={"/logs"}>Logs</Link>}
                    </StyledLeftSideLinksConatiner>
                    {token  && (
                        <StyledRightSideLinksConatiner>
                            {(hasHubCreatePermission || hasCloseHubsByCityPermission) && (
                                <StyledUserMenuButton
                                    onClick={handleOpenActionMenu}
                                    endIcon={<KeyboardArrowDownIcon/>}
                                >
                                    Actions
                                </StyledUserMenuButton>
                            )}

                            <Menu
                                sx={{mt: "50px"}}
                                anchorEl={anchorElActions}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={!!anchorElActions}
                                onClose={handleCloseActionMenu}>
                                {hasHubCreatePermission && (
                                    <MenuItem onClick={() => {navigate("/create-delivery-area"); handleCloseActionMenu();}}>
                                        <Typography textAlign="center">Create Delivery Area</Typography>
                                    </MenuItem>
                                )}
                                {hasHubCreatePermission && (
                                    <MenuItem onClick={() => {navigate("/createhub"); handleCloseActionMenu();}}>
                                        <Typography textAlign="center">Create Hub</Typography>
                                    </MenuItem>
                                )}
                                {hasAdminOrSupervisorPermission &&(
                                    <MenuItem onClick={() => {setOpen(true); handleCloseActionMenu();}}>
                                        <Typography textAlign="center">Close city hubs</Typography>
                                    </MenuItem>
                                )}

                                {hasAdminOrSupervisorPermission &&(
                                    <MenuItem onClick={() => {setOpenUpdate(true); handleCloseActionMenu();}}>
                                        <Typography textAlign="center">Upload CSV</Typography>
                                    </MenuItem>
                                )}
                            </Menu>

                            {token && (
                                <StyledUserMenuButton
                                onClick={handleOpenUserMenu}
                                endIcon={<KeyboardArrowDownIcon/>}
                            >
                                {user.username}
                            </StyledUserMenuButton>)}
                            <Menu
                                sx={{mt: "50px"}}
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={!!anchorElUser}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem onClick={signOut}>
                                    <Typography textAlign="center">Sign out</Typography>
                                </MenuItem>
                            </Menu>
                        </StyledRightSideLinksConatiner>
                    )}
                </StyledToolbar>
            </StyledNavigationContainer>

            <CloseHubsDialog
                open={open}
                onCancel={onCancelAction}
                title={"Close hubs by city"}
                message={`Please write down the city code: `}
            />

            <UpdateDeliveryAreaWithCSV
                open={openUpdate}
                onCancel={onCancelAction}
                title={"Update Delivery Areas"}
                message={`Please upload CSV file: `}
            />
        </StyledAppBar>
    );

};
export default ResponsiveAppBar;
