import { useState } from "react";
import { Button, Typography, Grid } from "@mui/material";
import { useForm } from "react-hook-form";

import {
  StyledHubFormPageContainer,
  StyledHubFormGridContainer,
  StyledHubFormTitleGridContainer,
} from "../styles";

import { usePermissionContext } from "../../context/PermissionContext";
import { useNotificationContext } from "../../context/NotificationContext";
import { useGetHubByIdQuery } from "../../sources/queries";
import {
  useEditHubMutation,
  useDeleteHubMutation,
} from "../../sources/mutations";

import { FormSkeleton } from "../../components/placeholders/FormSkeleton";
import { ConfirmationDialog } from "../../components/modals/ConfirmationDialog";

import { HubForm } from "../../components/form/HubForm";
import { schema } from "../../components/form/validation";
import { addTypeToProperties } from "../../components/form/propertyTypes";

export const EditHubPage = () => {
  const [open, setOpen] = useState(false);
  const methods = useForm({ resolver: schema });
  const { hasReadOnlyPermission } = usePermissionContext();
  const { notify } = useNotificationContext();

  const { data, isLoading: loadingHub } = useGetHubByIdQuery({
    onSuccess: (result) => {
      methods.reset(addTypeToProperties(result?.data));
    },
    keepPreviousData: false,
    cacheTime: 0,
  });
  const { mutate: updateHub, isLoading: loadingUpdateHub } = useEditHubMutation(
    {
      onSuccess: ({ data, success, message }) => {
        if (success) {
          methods.reset(data);
          notify({
            severity: "success",
            message: "Hub updated successfully !",
          });
        } else {
          notify({ severity: "error", message });
        }
      },
      onError: () => {
        notify({ severity: "error", message: "Something went wrong." });
      },
    }
  );
  const { mutate: deleteHub, isLoading: loadingDeleteHub } =
    useDeleteHubMutation();

  if (loadingHub || !data?.data) return <FormSkeleton />;

  const onCancel = () => {
    setOpen(false);
  };
  const onAction = () => {
    deleteHub();
  };

  return (
    <StyledHubFormPageContainer maxWidth="md">
      <StyledHubFormTitleGridContainer container>
        <Grid item xs={12}>
          <Typography variant="h4">
            {hasReadOnlyPermission ? "Hub Info" : "Edit Hub"}
          </Typography>
        </Grid>
      </StyledHubFormTitleGridContainer>
      <HubForm
        methods={methods}
        disabled={hasReadOnlyPermission}
        renderFormActions={() => {
          return (
            !hasReadOnlyPermission && (
              <StyledHubFormGridContainer container spacing={4}>
                <Grid item xs={8}>
                  <Button
                    fullWidth
                    variant="contained"
                    disableRipple
                    color="success"
                    onClick={methods.handleSubmit((fields) => {
                      updateHub({
                        ...fields,
                        etaReason: { id: fields.etaReason.id },
                        etaValue: { id: fields.etaValue.id },
                      });
                    })}
                    disabled={loadingUpdateHub || loadingDeleteHub}
                  >
                    Save Changes
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    disableRipple
                    color="error"
                    onClick={() => {
                      setOpen(true);
                    }}
                    disabled={loadingUpdateHub || loadingDeleteHub}
                  >
                    Delete Hub
                  </Button>
                </Grid>
              </StyledHubFormGridContainer>
            )
          );
        }}
      />

      <ConfirmationDialog
        open={open}
        onCancel={onCancel}
        onAction={onAction}
        title={"Are you sure?"}
        message={`This action cannot be undone. This will permanently delete ${data?.data?.id} hub and everything related to this hub`}
        property={true}
      />
    </StyledHubFormPageContainer>
  );
};
