import { useEffect } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";

import {
  StyledFormModalDialogContent,
  StyledFormModalGridContainer,
  StyledFormModalMenuItem,
} from "./styles";

import Select from "../mui-components/Select";
import TextField from "../mui-components/TextField";

import {
  useGetUserRolesQuery,
  useGetUserByIdQuery,
} from "../../sources/queries";
import {
  useCreateUserMutation,
  useEditUserMutation,
} from "../../sources/mutations";

import { useForm, FormProvider } from "react-hook-form";
import { createSchema, editSchema } from "./userValidation";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const UserModal = ({
  open,
  isEditMode = false,
  userId,
  onCancel = () => {},
}) => {
  const methods = useForm({
    defaultValues: {
      username: "",
      roles: [],
    },
    resolver: isEditMode ? editSchema : createSchema,
  });
  const { data, isLoading } = useGetUserRolesQuery({
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
  const roles = data?.data || [];
  const { data: userInfo } = useGetUserByIdQuery({
    userId,
    onSuccess: ({ data }) => {
      methods.reset({
        username: data?.username || "",
        roles: data?.roles?.map((role) => role.id) || [],
      });
    },
  });

  const resetModal = () => {
    onCancel();
    methods.reset({
      username: "",
      roles: [],
      password: "",
    });
  };

  const { mutate: createUser, isLoading: isCreateUserLoading } =
    useCreateUserMutation({ onSuccess: () => resetModal() });
  const { mutate: editUser, isLoading: isEditUserloading } =
    useEditUserMutation({ onSuccess: () => resetModal() });

  const onAction = async (state) => {
    const values = {
      ...state,
      roles: state.roles.map((id) => {
        return roles.find((el) => el.id === id);
      }),
    };
    Object.keys(values).forEach((key) =>
      values[key] === undefined ? delete values[key] : {}
    );

    if (isEditMode) {
      const editedUser = {
        id: userId,
        username: values.username,
        roles: values.roles,
        ...(values.password ? { password: values.password } : {}),
      };
      editUser(editedUser);
    } else {
      createUser(values);
    }
  };

  useEffect(() => {
    if (!isEditMode) {
      methods.reset({ username: "", roles: [] });
    }
  }, [isEditMode]);

  if (isLoading || !roles) return null;

  return (
    <Dialog fullWidth open={open} onClose={resetModal}>
      <DialogTitle>{isEditMode ? "Edit User" : "Create User"}</DialogTitle>
      <StyledFormModalDialogContent dividers>
        <FormProvider {...methods}>
          <StyledFormModalGridContainer container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="username"
                label="Username"
                variant="standard"
                disabled={isEditMode}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="password"
                label="Password"
                variant="standard"
                type="password"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="state-select-label">Roles</InputLabel>
                <Select
                  name="roles"
                  labelId="state-select-label"
                  label="Roles"
                  multiple
                  MenuProps={MenuProps}
                >
                  {roles?.map((role) => (
                    <StyledFormModalMenuItem key={role.id} value={role.id}>
                      {role.name}
                    </StyledFormModalMenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </StyledFormModalGridContainer>
        </FormProvider>
      </StyledFormModalDialogContent>
      <DialogActions>
        <Button onClick={resetModal}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={methods.handleSubmit(onAction)}
          disabled={isCreateUserLoading || isEditUserloading}
        >
          {isEditMode ? "Save Changes" : "Create User"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
