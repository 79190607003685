import SearchIcon from "@mui/icons-material/Search";
import {Button, InputAdornment} from "@mui/material";

import {
  StyledSearchHeaderContainer,
  StyledSearchHeaderTitle,
  StyledSearchInput,
} from "./styles";
import {useAuthContext} from "../context/AuthContext";
import {exportDeliveryAreas} from "../sources/queries";

export const SearchHeader = ({ header, setSearch }) => {
    const { token } = useAuthContext();
  return (
    <StyledSearchHeaderContainer>
      <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <StyledSearchHeaderTitle variant="h4">{header}</StyledSearchHeaderTitle>
          <StyledSearchInput
              placeholder="Search by hub id"
              variant="standard"
              onKeyPress={(e) => {
                  if (e.key === "Enter") {
                      setSearch(e.target.value);
                  }
              }}
              onKeyUp={(e) => {
                  if (e.key === "Backspace" && e.target.value === "") {
                      setSearch("");
                  }
              }}
              InputProps={{
                  startAdornment: (
                      <InputAdornment position="start">
                          <SearchIcon />
                      </InputAdornment>
                  ),
              }}
          />
      </div>
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    exportDeliveryAreas({token});
                }}
            >
                Export
            </Button>
        </>
    </StyledSearchHeaderContainer>
  );
};
