import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { getFromUrl } from "./methods";
import { getEnv } from "../utilities/getEnv";

const baseURL = getEnv("REACT_APP_API_ENDPOINT");

export const useGetHubsQuery = (options) => {
  const { token } = useAuthContext();
  const { search } = options;

  const searchURL = search
    ? `${baseURL}/hubs?search=${options.search}`
    : `${baseURL}/hubs`;

  const queryOptions = {
    ...options,
    enabled: !!token,
  };

  return useQuery(
    ["getAllHubs", { url: searchURL, token }],
    getFromUrl,
    queryOptions
  );
};

export const useGetDeliveryAreaQuery = (options) => {
  const { token } = useAuthContext();
  const { search } = options;

  const searchURL = search
    ? `${baseURL}/delivery-area?search=${options.search}`
    : `${baseURL}/delivery-area`;

  const queryOptions = {
    ...options,
    enabled: !!token,
  };

  return useQuery(
    ["getAllDeliveryAreas", { url: searchURL, token }],
    getFromUrl,
    queryOptions
  );
};

export const useGetHubByIdQuery = (options) => {
  const { id } = useParams();
  const { token } = useAuthContext();

  const queryOptions = {
    ...options,
    enabled: !!token,
    refetchOnWindowFocus: false,
  };

  return useQuery(
    ["getHubById", { url: `${baseURL}/hubs/${id}`, token }],
    getFromUrl,
    queryOptions
  );
};

export const useGetDeliveryAreaByIdQuery = (options) => {
  const { id } = useParams();
  const { token } = useAuthContext();

  const queryOptions = {
    ...options,
    enabled: !!token,
    refetchOnWindowFocus: false,
  };

  return useQuery(
    ["getDeliveryAreaById", { url: `${baseURL}/delivery-area/${id}`, token }],
    getFromUrl,
    queryOptions
  );
};

export const useGetCountryHubsQuery = (options) => {
  const { code } = useParams();
  const { token } = useAuthContext();

  const { search } = options;

  const searchURL = search
    ? `${baseURL}/hubs/country/${code.toUpperCase()}?search=${search}`
    : `${baseURL}/hubs/country/${code.toUpperCase()}`;

  const queryOptions = {
    ...options,
    enabled: !!token,
  };

  return useQuery(
    ["getCountryHubs", { url: searchURL, token }],
    getFromUrl,
    queryOptions
  );
};

export const useGetCountryDeliveryAreasQuery = (options) => {
  const { code } = useParams();
  const { token } = useAuthContext();

  const { search } = options;

  const searchURL = search
    ? `${baseURL}/delivery-area/country/${code.toUpperCase()}?search=${search}`
    : `${baseURL}/delivery-area/country/${code.toUpperCase()}`;

  const queryOptions = {
    ...options,
    enabled: !!token,
  };

  return useQuery(
    ["getCountryDeliveryAreas", { url: searchURL, token }],
    getFromUrl,
    queryOptions
  );
};

export const useGetCityHubsQuery = (options) => {
  const { code } = useParams();
  const { token } = useAuthContext();

  const { search } = options;

  const searchURL = search
    ? `${baseURL}/hubs/city/${code.toUpperCase()}?search=${search}`
    : `${baseURL}/hubs/city/${code.toUpperCase()}`;

  const queryOptions = {
    ...options,
    enabled: !!token,
  };

  return useQuery(
    ["getCityHubs", { url: searchURL, token }],
    getFromUrl,
    queryOptions
  );
};

export const useGetCityDeliveryAreasQuery = (options) => {
  const { code } = useParams();
  const { token } = useAuthContext();

  const { search } = options;

  const searchURL = search
    ? `${baseURL}/delivery-area/city/${code.toUpperCase()}?search=${search}`
    : `${baseURL}/delivery-area/city/${code.toUpperCase()}`;

  const queryOptions = {
    ...options,
    enabled: !!token,
  };

  return useQuery(
    ["getCityDeliveryAreas", { url: searchURL, token }],
    getFromUrl,
    queryOptions
  );
};

export const useGetETAQuery = (options) => {
  const { token } = useAuthContext();

  const queryOptions = {
    ...options,
    enabled: !!token,
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  };

  const getETA = async () => {
    const headers = {
      headers: {
        Authorization: token,
      },
    };
    const getResponse = await Promise.all([
      fetch(`${baseURL}/eta/values`, headers).then((resp) => resp.json()),
      fetch(`${baseURL}/eta/reasons`, headers).then((resp) => resp.json()),
    ]);

    return {
      etaValues: getResponse[0].data,
      etaReasons: getResponse[1].data,
    };
  };

  return useQuery(["getETA"], getETA, queryOptions);
};

export const useGetUsersQuery = (options) => {
  const { token } = useAuthContext();
  const { page, search, ...restOfOptions } = options;

  const queryOptions = {
    ...restOfOptions,
    enabled: !!token && typeof page === "number",
  };

  return useQuery(
    [
      "getUsers",
      { url: `${baseURL}/users?page=${page + 1}&search=${search}`, token },
    ],
    getFromUrl,
    queryOptions
  );
};

export const useGetUserRolesQuery = (options) => {
  const { token } = useAuthContext();

  const queryOptions = {
    ...options,
    enabled: !!token,
  };

  return useQuery(
    ["getUserRoles", { url: `${baseURL}/roles`, token }],
    getFromUrl,
    queryOptions
  );
};

export const useGetUserByIdQuery = (options) => {
  const { token } = useAuthContext();
  const { userId, ...restOfOptions } = options || {};

  const queryOptions = {
    ...restOfOptions,
    enabled: !!token && !!userId,
  };

  return useQuery(
    [
      "getUserById",
      {
        url: `${baseURL}/users/${userId}`,
        token,
      },
    ],
    getFromUrl,
    queryOptions
  );
};

export const useGetMapInfo = (options) => {
  const { token } = useAuthContext();
  const queryOptions = {
    ...options,
  };

  const headers = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  };

  const getMapInfo = async () => {
    const getResponse = await Promise.all([
      fetch(`${baseURL}/maps/polygons`, headers).then((resp) => resp.json()),
      fetch(`${baseURL}/maps/hub-labels`, headers).then((resp) => resp.json()),
    ]);

    return {
      hubPolygons: getResponse[0].data,
      hubLabels: getResponse[1].data,
    };
  };

  return useQuery(["getMapInfo"], getMapInfo, queryOptions);
};

export const useGetLogs = (options) => {
  const { token } = useAuthContext();
  const { startDate, endDate, page, search, ...restOfOptions } = options;
  const parseDate = (date, hour) =>
    new Date(date).toISOString().split("T")[0] + hour;

  const queryOptions = {
    ...restOfOptions,
    enabled: !!token,
    keepPreviousData: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
  };

  const handleLogs = () => {
    if (endDate && search) {
      return `logs?page=${page + 1}&search=${search}&firstDate=${parseDate(
        startDate,
        " 00:00"
      )}&lastDate=${parseDate(endDate, " 23:58")}`;
    } else if (endDate) {
      return `logs?page=${page + 1}&firstDate=${parseDate(
        startDate,
        " 00:00"
      )}&lastDate=${parseDate(endDate, " 23:58")}`;
    } else {
      return `logs?page=${page + 1}&search=${search}`;
    }
  };

  const handleLogsQuery = handleLogs();

  const logUrl = `${baseURL}/${handleLogsQuery}`;

  return useQuery(
    [
      "getLogs",
      {
        url: logUrl,
        token,
      },
    ],
    getFromUrl,
    queryOptions
  );
};

export const exportLogs = async ({ endDate, startDate, token, search }) => {
  const parseDate = (date, hour) =>
    new Date(date).toISOString().split("T")[0] + hour;

  const handleLogs = () => {
    if (endDate && search) {
      return `logs/export?&search=${search}&firstDate=${parseDate(
        startDate,
        " 00:00"
      )}&lastDate=${parseDate(endDate, " 23:58")}`;
    } else if (endDate) {
      return `logs/export?firstDate=${parseDate(
        startDate,
        " 00:00"
      )}&lastDate=${parseDate(endDate, " 23:58")}`;
    } else if (search) {
      return `logs/export?&search=${search}`;
    } else {
      return `logs/export`;
    }
  };

  const handleLogsQuery = handleLogs();

  const logUrl = `${baseURL}/${handleLogsQuery}`;

  let req = new XMLHttpRequest();
  req.open("GET", logUrl, true);
  req.setRequestHeader("authorization", token);
  req.responseType = "blob";

  req.onload = function (event) {
    let blob = req.response;
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `logs_${new Date()}.xlsx`;
    link.click();
    link.remove();
  };

  req.send();
};

export const exportDeliveryAreas = async ({token}) => {

  const deliveryAreaUrl = `${baseURL}/delivery-area/export`;

  let req = new XMLHttpRequest();
  req.open("GET", deliveryAreaUrl, true);
  req.setRequestHeader("authorization", token);
  req.responseType = "blob";

  req.onload = function (event) {
    let blob = req.response;
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `deliveryAreas_${new Date()}.csv`;
    link.click();
    link.remove();
  };

  req.send();
};

export const useGetHubIdsQuery = (options) => {
  const { token } = useAuthContext();

  const queryOptions = {
    ...options,
    enabled: !!token,
  };

  return useQuery(
      ["getHubIds", { url: `${baseURL}/hubs/read-ids`, token }],
      getFromUrl,
      queryOptions
  );
}
